
import * as React from "react";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import { Paper, } from "@mui/material";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useState, useEffect } from "react";
import { APIPath, token } from "../../config/api";

const StyledTableContainer = styled(TableContainer)(({ theme, open }) => ({
    width: open ? "80%" : "94%",
    transition: "width 0.3s ease",
    paddingTop: "7px",
    marginLeft: open ? "243px" : "69px",
    position: "relative",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    overflow: "hidden",
    marginBottom: "20px",
    boxShadow: "none",
}));

const AirportForm = ({ open, onClose,countryIdT,countryNameT,cityIdT,cityNameT }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [country,setCountry]=useState(null)
    const [countryName, setCountryName] = useState(countryNameT || "");
    const [countryId, setCountryId] = useState(countryIdT || "");
    const [city, setCity] = useState(null);
    const [cityName,setCityName]=useState(cityNameT || "")
    const [cityId, setCityId] = useState(cityIdT || "");
    const [airportName, setAirportName] = useState('');
   
    useEffect(()=>{
            fetch(`${APIPath}/api/v1/agency/country`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                method: 'GET',
                mode: 'cors',
            })
                .then((res) => res.json())
                .then((data) => {
                    setCountry(data.data);
                })
                .catch((err) => {
                    alert(err)
                })
    },[])

    useEffect(() => {
            fetch(`${APIPath}/api/v1/agency/city?country=${countryId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                method: 'GET',
                mode: 'cors',
            })
                .then((res) => res.json())
                .then((data) => {
                    setCity(data.data);
                })
                .catch((err) => {
                    alert(err)
                })
    },[countryId])

    const AddAirport = () => {
        setIsLoading(true)
        fetch(`${APIPath}/api/v1/agency/airport`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            mode: 'cors',
            body:JSON.stringify({
                "name": airportName,
                "country": countryId,
                "cityId": cityId
            })
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                setIsLoading(false);
                alert("New Airport Added...")
                onClose();
            })
            .catch((err) => {
                alert(err)
            })
    }

    return (
        <StyledTableContainer component={Paper} open={open}>
            <div className="add-country-container">
                <div className="add-country-form">
                    <form className="add-country" onSubmit={(e) => {
                        e.preventDefault();
                        AddAirport();
                    }}>
                        <h2>Add Airport</h2>
                        <div className="">
                            <div className="add-country-name">
                                <label>Select country *</label>
                                <select value={countryId} onChange={(e) => setCountryId(e.target.value)} required >
                                    <option value="" disabled >Select Country</option>
                                    {country?.map((country, id) => (
                                        <option key={country._id} value={country._id}>
                                            {country.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <br/>
                            <div className="add-country-name">
                                <label>Select city *</label>
                                <select value={cityId} onChange={(e) => setCityId(e.target.value)} required >
                                    <option value="" disabled>Select City</option>
                                    {city?.map((city, id) => (
                                        <option key={city._id} value={city._id}>
                                            {city.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <br />
                            <div className="add-country-name">
                                <label>Airport Name *</label>
                                <input
                                    type="text"
                                    placeholder="Type Airport name..."
                                    name="name"
                                    value={airportName}
                                    maxLength={30}
                                    onChange={(e) => {
                                        setAirportName(e.target.value);
                                    }}
                                    required
                                />
                            </div>
                        </div>
                        <div className="add-country-btn">
                            {isLoading ? (
                                <div className="loader"></div>
                            ) : (
                                <>
                                    <button onClick={onClose} id="cancel">Cancel</button>
                                    <button type="submit" id="addvehicle" >Add Airport</button>
                                </>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </StyledTableContainer>

    );
}

export default AirportForm;