import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ children }) => {

  const { isLoggedIn,user } = useSelector((state) => state.auth);
  console.log("isLoggedIn",isLoggedIn, user)

  if (!isLoggedIn) {
    console.log("auth please");
    return (
      <>
        <Navigate to="/login" />
      </>
    );
  }

  return children;
};

export default PrivateRoute;
