// src/store/authSlice.js
import { createSlice } from "@reduxjs/toolkit";

// Dummy users with passwords
const users = [
  { username: "user1", password: "password1" },
  { username: "user2", password: "password2" },
  { username: "user3", password: "password3" },
  { username: "user4", password: "password4" },
];

export const authSlice = createSlice({
  name: "auth",

  initialState: {
    isLoggedIn: localStorage.getItem("session") ?? false,

    user: null,
  },
  reducers: {
    login: (state, action) => {
      const { username, password } = action.payload;
      console.log("actionnn", action.payload);
      console.log(username, password);
      // Check if the provided username and password match any dummy user
      const authenticatedUser = users.find(
        (user) => user.username === username && user.password === password
      );

      if (authenticatedUser) {
        state.isLoggedIn = true;
        state.user = authenticatedUser;
        localStorage.setItem("session", state.isLoggedIn);
      }
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.user = null;
      localStorage.removeItem("session");
    },
  },
});

export const { login, logout } = authSlice.actions;
export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
export const selectCurrentUser = (state) => state.auth.user;

export default authSlice.reducer;
