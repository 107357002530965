import * as React from "react";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import { Paper } from "@mui/material";
import { APIPath, token } from "../../config/api";
import CreatePackageForm from "./CreateAttractionForm";

const StyledTableContainer = styled(TableContainer)(({ theme, open }) => ({
    width: open ? "80%" : "94%",
    transition: "width 0.3s ease",
    paddingTop: "7px",
    marginLeft: open ? "243px" : "69px",
    position: "relative",
    borderRadius: "0px",
    overflow: "hidden",
    marginBottom: "20px",
    boxShadow: "none"
}));


const PackageDetail = ({ open, close, pkgId,loadPackagesList }) => {
    document.body.style.overflow = 'hidden';
    const [packageData, setPackageData] = React.useState(null);
    const [editableAttraction, setEditableAttraction] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(true);

    const loadPackagesDetails = () => {
        fetch(`${APIPath}/api/v1/attractions?id=${pkgId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            method: 'GET',
            mode: "cors",
        })
            .then((res) => res.json())
            .then((data) => {
                // console.log(data.data);
                setPackageData(data.data);
                setIsLoading(false);
            })
            .catch((error) => {
                alert('Error fetching  Package Details:', error);
                setIsLoading(false);
            });
    }
    React.useEffect(() => {
        loadPackagesDetails();
    }, []);

    const DeletePackage = () => {
        let confirm = window.confirm("Are you sure you want to delete this Attraction?");
        if (confirm) {
            console.log("User confirmed deletion.");
            fetch(`${APIPath}/api/v1/agency/attractions?id=${pkgId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                method: 'DELETE',
                mode: "cors",
            })
                .then((res) => res.json())
                .then((data) => {
                    // console.log(data.data);
                    close();
                    loadPackagesList();
                })
                .catch((error) => {
                    alert('Error while deleting Attraction...', error);
                });
        } else {
            return
        }
    }

    const onclose = () => {
        setEditableAttraction(false);
        close();
    }

    return <>
        <StyledTableContainer component={Paper} open={open}>
            <div className="admin-package-detail-container">
                <div className="admin-package-detail-page">
                    {isLoading ? (
                        <div className="loader"></div>
                    ) : (<>
                        <div className="back-and-text">
                            <div className="back">
                                <img src="backicon.svg" alt="back-icon" onClick={close} />
                            </div>
                            <div className="text">
                                <h2>Package Details</h2>
                            </div>
                        </div>
                        <div className="package-basic-details">
                            <h3>Basic Details</h3>
                            <div className="admin-pkg-title-description">
                                <div className="title">
                                    <h4>Title:</h4>
                                    <h5>{packageData[0].title}</h5>
                                </div>
                                {/* <div className="title-value">
                                    <h5>{packageData[0].title}</h5>
                                </div> */}
                                <div className="title">
                                    <h4>Description:</h4>
                                    <h5>{packageData[0].attractionOverview}</h5>
                                </div>
                                {/* <div className="description-value">
                                    <h5>{packageData[0].attractionOverview}</h5>
                                </div> */}
                            </div>
                            <div className="admin-pkg-title-description-pic">
                                <div className="title">
                                    <h4>Cover Photo</h4>
                                </div>
                                <div className="title-value">
                                    <img src={packageData[0].bannerImage} height="100px" width="150px" />
                                </div>
                                <div className="description">
                                    <h4>Banner Photo</h4>
                                </div>
                                <div className="description-value">
                                    <img src={packageData[0].thumbnailImage} height="100px" width="150px" />
                                </div>
                            </div>
                        </div>
                        {/* <div className="package-basic-details">
                            <h3>Location Details</h3>
                            <div className="location-details">
                                <div className="title">
                                    <h4>From&nbsp;Country</h4>
                                </div>
                                <div className="title-value">
                                    <h5>India</h5>
                                </div>
                                <div className="description">
                                    <h4>To&nbsp;Country</h4>
                                </div>
                                <div className="description-value">
                                    <h5>UAE</h5>
                                </div>
                                <div className="description">
                                    <h4>Places&nbsp;Included</h4>
                                </div>
                                <div className="description-value">
                                    <h5>Burj Khalifa</h5>
                                </div>
                            </div>
                        </div> */}
                        <div className="package-basic-details">
                            <h3>Meeting Point</h3>
                            {packageData[0].expectations?.map((val, id) => {
                                return <>
                                    <div className="location-details">
                                        <div className="title">
                                            <h4>Place:</h4>
                                        </div>
                                        <div className="title-value">
                                            <h5>{val.location}</h5>
                                        </div>
                                        <div className="description">
                                            <h4>Description:</h4>
                                        </div>
                                        <div className="description-value">
                                            <h5>{val.description}</h5>
                                        </div>
                                        <div className="description">
                                            <h4>Open Time:</h4>
                                        </div>
                                        <div className="description-value">
                                            <h5>{val.time}</h5>
                                        </div>
                                    </div>
                                </>
                            })}
                        </div>
                        <div className="package-basic-details">
                            <h3>Inclusions/Exclusions</h3>
                            <div className="location-details">
                                <div className="title">
                                    <h4>Inclusions:</h4>
                                </div>
                                <div className="title-value">
                                    {/* <h5>Luxury</h5> */}
                                    {packageData[0]?.includedServices.map((val, id) => {
                                        return <>
                                            <ol>
                                                <p>{val}</p>
                                            </ol>
                                        </>
                                    })}
                                </div>
                                <div className="description">
                                    <h4>Exclusions:</h4>
                                </div>
                                <div className="description-value">
                                    {/* <h5>Group Tour</h5> */}
                                    {packageData[0]?.excludedServices.map((val, id) => {
                                        return <>
                                            <ol>
                                                <p>{val}</p>
                                            </ol>
                                        </>
                                    })}
                                </div>

                            </div>
                        </div>
                        <div className="package-price">
                            <h3>Pricing</h3>
                            <div style={{ width: "70%", marginTop: "1rem" }}>
                                {packageData[0].price.map((val, id) => {
                                    return <>
                                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "1rem" }}>
                                            <div>
                                                <p>Description</p>
                                                <h4>{val.description}</h4>
                                            </div>
                                            <div>
                                                <p>Adults Price</p>
                                                <h4>AED {val.adultPrice}</h4>
                                            </div>
                                            <div>
                                                <p>Children Price</p>
                                                <h4>AED {val.childPrice}</h4>
                                            </div>
                                        </div>
                                    </>
                                })}
                            </div>
                        </div>
                        <div className="package-delete-edit-btn">
                            <button id="delete"
                                onClick={() => {
                                    DeletePackage();
                                }}
                            >
                                <img src="delete1.svg" />
                                Delete
                            </button>
                            <button id="edit"
                                onClick={() => {
                                    setEditableAttraction(true)
                                }}
                            >
                                <img src="editicon.svg" />
                                Edit
                            </button>
                        </div>

                    </>)}
                </div>
            </div>
        </StyledTableContainer>
        {editableAttraction && <CreatePackageForm close={onclose}
            attractionEditedId={packageData[0]._id}
            titleP={packageData[0].title}
            descriptionP={packageData[0].attractionOverview}
            imageCoverP={packageData[0].bannerImage}
            imageBannerP={packageData[0].thumbnailImage}
            inclusionP={packageData[0]?.includedServices}
            exclusionP={packageData[0]?.excludedServices}
            exceptions={packageData[0]?.expectations}
            Price={packageData[0]?.price}
            loadPackagesList={loadPackagesList}
        />}
    </>
};

export default PackageDetail;
