
import * as React from "react";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import { Paper, } from "@mui/material";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useState, useEffect } from "react";
import { APIPath, token } from "../../config/api";
import './City.css';

const StyledTableContainer = styled(TableContainer)(({ theme, open }) => ({
    width: open ? "80%" : "94%",
    transition: "width 0.3s ease",
    paddingTop: "7px",
    marginLeft: open ? "243px" : "69px",
    position: "relative",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    overflow: "hidden",
    marginBottom: "20px",
    boxShadow: "none",
}));

const CityForm = ({ open, onClose,CityList }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [cityName, setCityName] = useState('');
    const [countryName, setCountryName] = useState(null);
    const [countryId, setCountryId] = useState("");

    const CountrylName = () => {
        fetch(`${APIPath}/api/v1/agency/country`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            method: 'GET',
            mode: 'cors',
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                setCountryName(data.data)
            })
            .catch((err) => {
                alert(err)
            })
    }
    useEffect(() => {
        CountrylName();
    }, [])

    const CitylName = () => {
        setIsLoading(true);
        fetch(`${APIPath}/api/v1/agency/city`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                "name": cityName,
                "country": countryId
            })
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                alert(data.message)
                CityList();
                setIsLoading(false);
                onClose();
            })
            .catch((err) => {
                alert(err)
                setIsLoading(false);
            })
    }

    return (
        <StyledTableContainer component={Paper} open={open}>
            <div className="add-country-container">
                <div className="add-country-form">
                    <form className="add-country" onSubmit={(e) => {
                        e.preventDefault();
                        CitylName();
                    }}>
                        <h2>Add City</h2>
                        <div className="">
                            <div className="add-country-name">
                                <label>Select country *</label>
                                <select value={countryId} onChange={(e) => setCountryId(e.target.value)} required >
                                    <option value="" disabled>Select Country</option>
                                    {countryName?.map((country) => (
                                        <option key={country._id} value={country._id}>
                                            {country.name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <br />
                            <div className="add-country-name">
                                <label>City Name *</label>
                                <input
                                    type="text"
                                    placeholder="Type City name..."
                                    name="name"
                                    maxLength={30}
                                    value={cityName}
                                    onChange={(e) => {
                                        setCityName(e.target.value);
                                    }}
                                    required
                                />
                            </div>
                        </div>
                        <div className="add-country-btn">
                            {isLoading ? (
                                <div className="loader"></div>
                            ) : (
                                <>
                                    <button onClick={onClose} id="cancel">Cancel</button>
                                    <button type="submit" id="addvehicle" >Add City</button>
                                </>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </StyledTableContainer>

    );
}

export default CityForm;