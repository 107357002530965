const API_BASE_URL = "https://admin.magicalvacation.com/api/v1";

const fetchPackages = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/packages`);
    if (!response.ok) {
      throw new Error("Failed to fetch packages");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching packages:", error.message);
    throw error;
  }
};

const deletePackage = async (packageId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/packages/${packageId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        // Add any additional headers if required (e.g., authentication token)
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to delete package with ID ${packageId}`);
    }

    console.log(`Package with ID ${packageId} deleted successfully`);
  } catch (error) {
    console.error("Error deleting package:", error.message);
    throw error;
  }
};
export { fetchPackages, deletePackage };
