const API_BASE_URL = "https://admin.magicalvacation.com/api/v1";

const fetchPaymentLinks = async () => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/agent/stripe/payment-links`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJrZXkiOiJWVHFQMVJMT1pVV290cXZBb21wbW9XUmtPVHhxSFNydHhZdy8xalRaRDRFcVcyTStvUWtha1c0QndGRkhGN29OT1pZN1drTE1MUEZGQ1FvVStYelFiZ0ZhL0hFWTQ3MWtJSnZtc3RPMFF2QkxTaGxuaHhqZ09EdlFMVDVXdVZNNEMxbFl5TGc2NUc3cVlXREJDbHlGU3c9PSIsImlhdCI6MTcwMjg3Nzk4NX0.8NEHwUEvd6B6iMdMCgnoERhngTLtk4fedlrNQSow9Ws",
        },
      }
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      throw new Error("Failed to generate payment link.");
    }
  } catch (error) {
    throw new Error("Error generating payment link.");
  }
};

const generatePaymentLinkAndSendMail = async (data) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/agent/stripe/generate-payment-link`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJrZXkiOiJWVHFQMVJMT1pVV290cXZBb21wbW9XUmtPVHhxSFNydHhZdy8xalRaRDRFcVcyTStvUWtha1c0QndGRkhGN29OT1pZN1drTE1MUEZGQ1FvVStYelFiZ0ZhL0hFWTQ3MWtJSnZtc3RPMFF2QkxTaGxuaHhqZ09EdlFMVDVXdVZNNEMxbFl5TGc2NUc3cVlXREJDbHlGU3c9PSIsImlhdCI6MTcwMjg3Nzk4NX0.8NEHwUEvd6B6iMdMCgnoERhngTLtk4fedlrNQSow9Ws",
        },
        body: JSON.stringify(data),
      }
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      throw new Error("Failed to generate payment link.");
    }
  } catch (error) {
    throw new Error("Error generating payment link.");
  }
};

export { fetchPaymentLinks, generatePaymentLinkAndSendMail };
