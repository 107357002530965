import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";
import * as React from "react";
import Box from "@mui/material/Box";
import { TbSearch } from "react-icons/tb";
import TransferBookings from "./TransferBooking"; 
import PackagesList from "./package/PackagesList";
import AttractionList from "./attraction/AttractionList";
import LandComboList from "./landcombos/LandComboList";
import AllList from "./All/All";
import CustomList from "./CustomBooking/CustomLIst";


const StyledDashboardContainer = styled(Grid)(({ theme, open }) => ({
  marginTop: "1%",
  marginLeft: open ? theme.spacing(29) : theme.spacing(7),
  width: open ? "80%" : "94%",
  transition: "margin-left 0.3s ease",
  background:"rgba(204,204,204,0.2)",
  padding:"1rem 1rem 1rem 1rem",
}));

const Booking = ({ open }) => {
  const [value, setValue] = React.useState("All");
  const [searchText,setSearchText]=React.useState('')

  const handleChange = (newValue) => {
    if (newValue === "All") {
      setValue(newValue);
    }
    if (newValue === "Transfers") {
      setValue(newValue);
    }
    if (newValue === "Packages") {
      setValue(newValue);
    }
    if (newValue === "Attraction") {
      setValue(newValue);
    }
    if (newValue === "LandCombos") {
      setValue(newValue);
    }
    if(newValue === "Custom"){
      setValue(newValue);
    }

  };

  const renderTable = () => {
    switch (value) {
      case "All" :
        return <AllList searchText={searchText} />
      case "Transfers":
        return <TransferBookings searchText={searchText} />;
      case "Packages":
        return <PackagesList searchText={searchText} />;
      case "Attraction":
        return <AttractionList searchText={searchText} />;
      case "LandCombos":
        return <LandComboList searchText={searchText} />;
      case "Custom":
        return <CustomList searchText={searchText} />
      default:
        return ;
    }
  };
  
  


  return (
    <StyledDashboardContainer container spacing={2} open={open}>
      <Box sx={{ width: "100%", display: "flex", 
      justifyContent: "space-between",padding:"20px",
      marginLeft:"1rem",
      borderTopLeftRadius:"10px",
      borderTopRightRadius:"10px",
      alignItems:"center",background:"#fff"}}>
        <div className="search-from-list">
          <input  style={{border:"none",outline:"none",background:"none"}}
          type="text" placeholder="Search..." onChange={(e)=>{
            e.preventDefault();
            setSearchText(e.target.value)
          }}/>
          <TbSearch style={{color:"skyblue"}}/>
        </div>

        <select style={{ width: "fit-content", border: "1px solid skyblue", 
        padding: " 5px 10px",borderRadius:"20px",fontSize:"16px",color:"#00081d",outline:"none" }}
          onChange={(e) => {
            renderTable(e.target.value);
            handleChange(e.target.value);
          }}
        >
          <option value="All">All</option>
          <option value="Packages">Packages</option>
          <option value="Attraction">Attraction</option>
          <option value="LandCombos">LandCombos</option>
          <option value="Transfers">Transfers</option>
          <option value="Custom">Custom</option>
        </select>
      </Box>
      {
        renderTable()
      }
    </StyledDashboardContainer>
  );
};

export default Booking;
