import * as React from "react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { token,APIPath } from "../../config/api";

import TransferBookingDetail from "./TransferBookingDetail";
import './TransferBooking.css'
import { styled } from "@mui/material/styles";
import {
    Stack,
    Pagination,
} from "@mui/material";

const StyledPaginationContainer = styled("div")({
  background: "#fff",
  margin: " 2rem auto 0 auto",
  zIndex:1,
  background:"none",
});

export default function TransferList({ searchText }) {
  const [transferBookings, setTransferBookings] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isCreateFormOpen, setCreateFormOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [perPage] = React.useState(15);

  const [bookingId, setBookingId] = React.useState("");
  const transferList = () => {
    fetch(`${APIPath}/api/v1/agency/bookings/transfers`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
      mode: "cors",
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data.data[0]._id);
        setTransferBookings(data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching vehicle list:', error);
        setIsLoading(false);
      });
  }

  React.useEffect(() => {
    transferList()
  }, [])

  const indexOfLastItem = currentPage * perPage;
  const indexOfFirstItem = indexOfLastItem - perPage;
  const currentItems = transferBookings && transferBookings.slice(indexOfFirstItem, indexOfLastItem);

  const filterdata = currentItems?.filter(item =>
      item.bookingID.toLowerCase().includes(searchText.toLowerCase())
    );

  // const filteredTransferdata = transferBookings.filter(item =>
  //   item.agentEmail.toLowerCase().includes(searchText.toLowerCase())
  // );

  const handleCreateFormOpen = (_id) => {
    console.log("CREATEDDDDDDDDD!!!", _id);
    setBookingId(_id);
    setCreateFormOpen(true);
  };

  const handleCreateFormClose = () => {
    setCreateFormOpen(false);
    transferList()
  };

  return <>
    {isLoading ? (
      <div className="loader">

      </div>) : (
      <>
        {transferBookings?.length > 0 ? (
          <>
            <div className="package-list-container">
              <table>
                <thead>
                  <tr>
                    <th>Booking Id</th>
                    <th>Agent Name</th>
                    <th>Agent Email</th>
                    <th>Booking Date</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filterdata?.map((val, id) => {
                    return <>
                      <tr key={id}>
                        <td>{val.bookingID}</td>
                        <td>{val.agentName}</td>
                        <td>{val.agentEmail}</td>
                        <td>{val.createdAt.split("T")[0]}</td>
                        <td>{val.totalCost}</td>
                        <td><p id={(val.bookingStatus === "Pending" && "pending") ||
                          (val.bookingStatus === "On Hold" && "on-hold") ||
                          (val.bookingStatus === "Awaiting Payment" && "Awaiting_Payment") ||
                          (val.bookingStatus === "Confirmed" && "confirm") ||
                          (val.bookingStatus === "Rejected" && "rejected")
                        }>{val.bookingStatus}</p></td>
                        <td>
                          <button
                            onClick={() => {
                              handleCreateFormOpen(val._id)
                            }}
                          >
                            <MdOutlineRemoveRedEye />
                          </button>
                        </td>
                      </tr>
                    </>
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div style={{ marginLeft: "1rem" }}>
            <h2>No Record Found</h2>
          </div>
        )}
      </>
    )}

    {isCreateFormOpen && (
      <TransferBookingDetail
        isOpen={isCreateFormOpen}
        onClose={handleCreateFormClose}
        id={bookingId}
      // onPaymentLinkSent={handleRefresh}
      />
    )}
            <StyledPaginationContainer>
                    <Stack spacing={1}>
                        <Pagination
                            count={Math.ceil((transferBookings && transferBookings.length) / perPage)}
                            // count={1}
                            page={currentPage}
                            onChange={(event, value) => setCurrentPage(value)}
                            variant="outlined"
                            color="primary"
                            boundaryCount={1}
                        />
                    </Stack>
        </StyledPaginationContainer>
  </>

}
