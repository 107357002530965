import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Paper, } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import { IoIosSearch } from "react-icons/io";
import {
    Stack,
    Pagination,
} from "@mui/material";
import { token, APIPath } from "../../config/api";
import AirportForm from "./AirportForm";

const StyledTableContainer = styled(TableContainer)(({ theme, open }) => ({
    marginTop: "1%",
    marginLeft: open ? theme.spacing(29) : theme.spacing(7),
    width: open ? "80%" : "94%",
    height: "100vh",
    transition: "margin-left 0.3s ease",
    background: "rgba(204,204,204,0.2)",
    padding: "1rem 1rem 1rem 1rem",
    boxShadow: "none",
}));
const StyledPaginationContainer = styled("div")({
    background: "#fff",
    margin: "2rem auto 0 auto;",
    zIndex: 1,
    background: "none",
    display: "flex",
    justifyContent: "center"
});

const AirportList = ({ open }) => {
    const [airportForm, setAirportForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [airportList,setAirportList]=useState(null);
    const [searchText, setsearchText] = React.useState('');

    const AirportList=()=>{
        setIsLoading(true)
        fetch(`${APIPath}/api/v1/agency/airport`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            method: 'GET',
            mode: 'cors',
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                setAirportList(data.data)
                setIsLoading(false)
            })
            .catch((err) => {
                alert(err)
            })
    }

    useEffect(() => {
        AirportList();
    }, [])
    const onClose = () => {
        setAirportForm(false);
        AirportList();
    }
    const onShow = () => {
        setAirportForm(true)
    }
    const filterdata = airportList?.filter(item =>
        item.cityName?.toLowerCase().includes(searchText.toLowerCase())
      );
    const deleteAirport=(id)=>{
        setIsLoading(true);
        fetch(`${APIPath}/api/v1/agency/airport?id=${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            mode: 'cors',
        })
            .then((res) => res.json())
            .then((data) => {
                alert("Airport deleted...");
                AirportList();
                setIsLoading(false)
            })
            .catch((err) => {
                alert(err)
                setIsLoading(false)
            })

    }

    const Uppercase = (str) => str.toUpperCase();
    const capitalize = (str) => {
        if (typeof str !== 'string') return '';
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      };

    return (
        <>
            <StyledTableContainer component={Paper} open={open}>
                <div style={{ display: "flex", justifyContent: "space-between", padding: "10px 20px" }}>
                    <h2 style={{ fontSize: "18px" }}>Airport</h2>
                    <button className="add-hotel"
                        onClick={onShow}
                    >Add Airport</button>
                </div>
                <div className="package-search-table" style={{ paddingBottom: "2rem" }}>
                    <div className="package-search">
                        <input type="text" placeholder="Search.."
                            value={searchText} onChange={(e) => {
                                setsearchText(e.target.value)
                            }}
                        />
                        <IoIosSearch style={{ cursor: "pointer", color: "skyblue" }} />
                    </div>
                    <div className="package-table" style={{
                        width: "fit-content",
                        marginLeft: "1rem",
                        border: "1px solid #dedede",
                        boxShadow: "0 0 100px #dedede",
                        borderBottomRightRadius: "20px",
                        borderBottomLeftRadius:"20px",
                    }}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Serial No.</th>
                                    <th>Country Name</th>
                                    <th>City Name</th>
                                    <th>Airport Name</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            {isLoading ? <div className="loader"></div> :
                            <>
                            <tbody>
                                {filterdata?.map((val, id) => {
                                    return <>
                                        <tr key={id}>
                                            <td>{id + 1}</td>
                                            <td>{Uppercase(val.countryName)}</td>
                                            <td>{capitalize(val.cityName)}</td>
                                            <td>{capitalize(val.name)}</td>
                                            <td> <button id="country-delete"
                                             onClick={()=>{
                                                deleteAirport(val._id)
                                            }}
                                            >
                                                <img src="./deleteicon.svg" />
                                            </button>
                                            </td>
                                        </tr>
                                    </>
                                })}
                            </tbody>
                            </>}
                        </table>
                    </div>
                </div>
                {/* <StyledPaginationContainer>
                    <Stack spacing={1}>
                        <Pagination
                            count={Math.ceil((vehicleList && vehicleList?.length) / perPage)}
                            // count={1}
                            page={currentPage}
                            onChange={(event, value) => setCurrentPage(value)}
                            variant="outlined"
                            color="primary"
                            boundaryCount={1}
                        />
                    </Stack>
                </StyledPaginationContainer> */}
            </StyledTableContainer>
            {airportForm && <AirportForm onClose={onClose} onShow={onShow} AirportList={AirportList} />}
        </>
    );
}

export default AirportList;
