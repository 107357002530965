import * as React from "react";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import {Paper,} from "@mui/material";
import { APIPath, token } from "../../config/api";

const StyledTableContainer = styled(TableContainer)(({ theme, open }) => ({
    width: open ? "80%" : "94%",
    transition: "width 0.3s ease",
    paddingTop: "7px",
    marginLeft: open ? "243px" : "69px",
    position: "relative",
    borderRadius: "0px",
    overflow: "hidden",
    marginBottom: "20px",
    boxShadow: "none"
}));

const PackageDetail = ({packageEditedId,
    open, close, closeform,
    title, description,
    imageCoverP, imageBannerP,
    toCountry,toCountryName, fromCountry, fromCountryName,place,placeName,
    type, category, categoryName, duration, durationName,
    inclusion, exclusion,
    dayWisePlan,
    currency, adultsprice, childPrice, loadPackagesList
}) => {
    document.body.style.overflow = 'hidden';
    // console.log("Cover Imagage--",imageCoverP, "Banner Image--",imageBannerP)
    
    const [isLoading, setIsLoading] = React.useState(false);
    
    const method=packageEditedId?.length > 0 ? 'PATCH' : 'POST';

    const AddPackageData = {
        categoryId:category,
        categoryName:categoryName,
        title: title,
        packageOverview: description,
        duration: durationName,
        dayWisePlan: dayWisePlan,
        price: [
            {
                travelerType: "adult",
                ageGroup: "above 18",
                price: adultsprice,
                inclusiveOfTaxes: false
            },
            {
                travelerType: "child",
                ageGroup: "below 18",
                price: childPrice,
                inclusiveOfTaxes: false
            },
            {
                travelerType: "infant",
                ageGroup: "above 18",
                price: childPrice,
                inclusiveOfTaxes: false
            }
        ],
        includedServices: inclusion,
        excludedServices: exclusion,
        bookingProcedure: [
            "Date of Booking to 30 days prior to travel 30 % and bookings done which are non-refundable.",
            "30 to 15 days prior to travel 50% cancellation",
            "15 to 7 days prior to travel 75% cancellation",
            "0 to 7 days prior to travel or No show 100% cancellation"
        ],
        cancellationRefundPolicy: [
            "Cancellation Policy is subject to change. It purely depends upon the cancellation terms of the respective Hotel and the peak/off season rates. Refunds will take 15 – 30 working days to be credited back to the bank account. Airline Refund will be credited back once PML receives the same from the Airline."
        ],
        mustCarry: [
            "Return Air ticket.",
            "Hotel Voucher.",
            "Final COVID certificate.",
            "Personal medication with DR.prescription.",
            "Oversea Medical Insurance."
        ],
        ratings: 4.5
    }
    const [imageCover, setImageCover] = React.useState(null);
    const [imageBanner, setImageBanner] = React.useState(null);
    const convertUrlToFile = async (url) => {
      const response = await fetch(url);
      const blob = await response.blob();
      const fileName = url.split('/').pop();
      return new File([blob], fileName, { type: blob.type });
    };
  
    const AddNewPackage = async () => {
      const coverFile = await convertUrlToFile(imageCoverP);
      const bannerFile = await convertUrlToFile(imageBannerP);
      setImageCover(coverFile);
      setImageBanner(bannerFile);
      sendDataToServer(AddPackageData, coverFile, bannerFile);
    };
    // const AddNewPackage = () => {
    //     sendDataToServer(AddPackageData, imageCover, imageBanner);
    // };
    const sendDataToServer = async (AddPackageData, imageCover, imageBanner) => {
        try {
            showLoader();
            const formData = new FormData();
            formData.append('jsonData', JSON.stringify(AddPackageData));
            formData.append('thumbnail', imageCover);
            formData.append('banner', imageBanner);
            const response = await fetch(`${APIPath}/api/v1/agency/packages?id=${packageEditedId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                method: method,
                mode: 'cors',
                body: formData
            });
            hideLoader();
            if (!response.ok) {
                console.log(response);
                alert("Failed to add Package");
                return;
            }
            if(method === 'POST'){
                alert("New Package added...");
            }
            if(method === 'PATCH'){
                alert("Package updated...")
            }
            const responseData = await response.json();
            close();
            closeform();
            loadPackagesList();
        } catch (error) {
            console.error('Error sending data to server:', error);
            hideLoader();
            alert('An error occurred while adding the package');
        }
    };

    const showLoader = () => {
        setIsLoading(true);
    };

    const hideLoader = () => {
        setIsLoading(false);
    };

    return <StyledTableContainer component={Paper} open={open}>
            <div className="admin-package-detail-container" >
                <div className="admin-package-detail-page">
                    <div className="back-and-text">
                        <div className="back">
                            <img src="backicon.svg" alt="back-icon" onClick={close} />
                        </div>
                        <div className="text">
                            <h2>Package Details</h2>
                        </div>
                    </div>
                    <div className="package-basic-details">
                        <h3>Basic Details</h3>
                        <div className="admin-pkg-title-description">
                            <div className="title">
                                <h4>Title</h4>
                            </div>
                            <div className="title-value">
                                <h5>{title}</h5>
                            </div>
                            <div className="description">
                                <h4>Description</h4>
                            </div>
                            <div className="description-value">
                                <h5>{description}</h5>
                            </div>
                        </div>
                        <div className="admin-pkg-title-description-pic">
                            <div className="title">
                                <h4>Cover Photo</h4>
                            </div>
                            <div className="title-value">
                                <img src={imageCoverP} height="100px" width="150px" />
                            </div>
                            <div className="description">
                                <h4>Banner Photo</h4>
                            </div>
                            <div className="description-value">
                                <img src={imageBannerP} height="100px" width="150px" />
                            </div>
                        </div>
                    </div>
                    <div className="package-basic-details">
                        <h3>Location Details</h3>
                        <div className="location-details">
                            <div className="title">
                                <h4>From&nbsp;Country</h4>
                            </div>
                            <div className="title-value">
                                <h5>{fromCountryName}</h5>
                            </div>
                            <div className="description">
                                <h4>To&nbsp;Country</h4>
                            </div>
                            <div className="description-value">
                                <h5>{toCountryName}</h5>
                            </div>
                            <div className="description">
                                <h4>Places&nbsp;Included</h4>
                            </div>
                            <div className="description-value">
                                <h5>{placeName}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="package-basic-details">
                        <h3>Package Type</h3>
                        <div className="location-details">
                            <div className="title">
                                <h4>Type</h4>
                            </div>
                            <div className="title-value">
                                <h5>{type}</h5>
                            </div>
                            <div className="description">
                                <h4>Category</h4>
                            </div>
                            <div className="description-value">
                                <h5>{categoryName}</h5>
                            </div>
                            <div className="description">
                                <h4>Duration</h4>
                            </div>
                            <div className="description-value">
                                <h5>{durationName}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="package-basic-details">
                        <h3>Inclusions/Exclusions</h3>
                        <div className="location-details">
                            <div className="title">
                                <h4>Inclusions</h4>
                            </div>
                            <div className="title-value">

                                {inclusion?.map((val) => {
                                    return <>
                                        <ol>
                                            <p>{val}</p>
                                        </ol>
                                    </>
                                })}
                            </div>
                            <div className="description">
                                <h4>Exclusions</h4>
                            </div>
                            <div className="description-value">

                                {exclusion?.map((val) => {
                                    return <>
                                        <ol>
                                            <p>{val}</p>
                                        </ol>
                                    </>
                                })}
                            </div>

                        </div>
                    </div>
                    <div className="package-basic-details">
                        <h3>Detail Itinerary</h3>
                        {dayWisePlan?.map((val, id) => {
                            return <>
                                <div className="location-details">
                                    <div className="title">
                                        <h4>{val.title}</h4>
                                    </div>
                                    <div className="title-value">
                                        <h6>{val.description}</h6>
                                    </div>
                                </div>
                            </>
                        })}
                    </div>
                    <div className="package-price">
                        <h3>Pricing</h3>
                        <div>
                            <p>Adults</p>
                            <h4>{currency} {adultsprice}</h4>
                        </div>
                        <div>
                            <p>Children</p>
                            <h4>{currency} {childPrice}</h4>
                        </div>
                    </div>
                    <div className="package-delete-edit-btn">
                        {isLoading ? (
                            <div className="loader">

                            </div>
                        ) : (
                            <>
                                <button id="delete" onClick={close}>
                                    Back
                                </button>
                                <button id="edit"
                                    onClick={() => {
                                        AddNewPackage();
                                    }}
                                    disabled={isLoading}
                                >
                                    Submit
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </StyledTableContainer>
};

export default PackageDetail;
