import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../components/dashboard/Dashboard";
import Agent from "../components/Agent/Agent";
import PackagesList from "../components/packages/PackagesList";
import AttractionsList from "../components/attractions/AttractionList"
import Payment from "../components/payments/Payments";
import Login from "../components/auth/Login";
import PrivateRoute from "../components/common/PrivateRoute";
import LandCombosList from "../components/land&combos/LandCombosList";
import HotelsList from "../components/hotels/HotelsList";
import PaymentLink from "../components/payments/paymentLinks/PaymentLink";
import TransferList from "../components/transfers/TransferList";
import Booking from "../components/bookings/booking";
import Failure from "../components/payments/failurePayment";
import Success from "../components/payments/successPayment";
import VehicleList from "../components/Vehicle/VehicleList";
import Revenue from "../components/Revenue/Revenue";
import CountryList from "../components/Country/CountryList";
import CityList from "../components/City/CityList";
import AirportList from "../components/Airport/AirportList";
import TransferForm from "../components/transfers/TransferForm";
import ZoneList from "../components/AddZone/ZoneList";
import AgentMarkup from "../components/AgentMarkup/AgentMarkup";

const AllRoutes = ({ open }) => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/success" element={<Success />} />
      <Route path="/failure" element={<Failure />} />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Dashboard open={open} />
          </PrivateRoute>
        }isLoggedIn
      />
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Dashboard open={open} />
          </PrivateRoute>
        }
      />
      <Route
        path="/agent"
        element={
          <PrivateRoute>
            <Agent open={open} />
          </PrivateRoute>
        }
      />
      <Route
        path="/markup"
        element={
          <PrivateRoute>
            <AgentMarkup open={open} />
          </PrivateRoute>
        }
      />
      <Route
        path="/bookings"
        element={
          <PrivateRoute>
            <Booking type="bookings" open={open} />
          </PrivateRoute>
        }
      />
      <Route 
      path="/revenue" 
      element={
        <PrivateRoute>
          <Revenue type="revenue" open={open}/>
        </PrivateRoute>
      }
      />
      <Route
        path="/packages"
        element={
          <PrivateRoute>
            <PackagesList type="packages" open={open} />
          </PrivateRoute>
        }
      />
      <Route
        path="/attractions"
        element={<AttractionsList type="attractions" open={open} />}
      />
      <Route
        path="/land-combos"
        element={<LandCombosList type="landcombos" open={open} />}
      />
      <Route path="/transfer" element={<TransferList type="transfers" open={open} />}/>
      <Route path="/vehicle" element={<VehicleList type="vehicle" open={open}/>}/>
      <Route path="/country" element={<CountryList type="country" open={open} />}/>
      <Route path="/city" element={<CityList type="city" open={open}/>}/>
      <Route path="/airport" element={< AirportList type="airport" open={open} />} />
      <Route path="/hotel" element={<HotelsList type="hotel" open={open} />}/>
      <Route path="/zone" element={<ZoneList type="zone" open={open} />}/>
      <Route
        path="/articles"
        element={<PackagesList type="packages" open={open} />}
      />
      <Route
        path="/shopping-stops"
        element={<AttractionsList type="attractions" open={open} />}
      />
      <Route
        path="/delicacies"
        element={<AttractionsList type="attractions" open={open} />}
      />
      <Route
        path="/categories"
        element={<PackagesList type="packages" open={open} />}
      />
      <Route
        path="/reviews"
        element={<AttractionsList type="attractions" open={open} />}
      />
      <Route
        path="/subscribers"
        element={<AttractionsList type="attractions" open={open} />}
      />

      <Route
        path="/payments"
        element={<Payment type="payments" open={open} />}
      />

      <Route
        path="/payment-list"
        element={<Payment type="payments" open={open} />}
      />

      <Route
        path="/payment-link"
        element={<PaymentLink type="payments-links" open={open} />}
      />
    </Routes>
  );
};

export default AllRoutes;
