
import { APIPath, token } from "../config/api";
const API_BASE_URL = "https://admin.magicalvacation.com/api/v1";
const fetchTransferBookings = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/agency/bookings/transfers`);
    if (!response.ok) {
      throw new Error("Failed to fetch packages");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching packages:", error.message);
    throw error;
  }
};

const updateBookingStatus = async (data, id) => {
  try {
    const response = await fetch(
      `${APIPath}/api/v1/agency/bookings/transfers?id=${id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization:`Bearer ${token}`,
        },
        body: JSON.stringify(data),
      }
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      throw new Error("Failed to update status.");
    }
  } catch (error) {
    throw new Error("Error while updating status.");
  }
};
export { fetchTransferBookings, updateBookingStatus };
