import React, { useEffect, useState } from "react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import './All.css';
import BookPackageDetails from "../package/BookPackageDetails";
import { token, APIPath } from "../../../config/api";
import { styled } from "@mui/material/styles";
import {
    Stack,
    Pagination,
} from "@mui/material";
import TransferBookingDetail from "../TransferBookingDetail";
import BookAttractionDetails from "../attraction/BookAttractionDetails";
import BookLandComboDetails from "../landcombos/BookLandComboDetails";
import BookCustomDetails from "../CustomBooking/CustomBookingDetails";

const StyledPaginationContainer = styled("div")({
    background: "#fff",
    margin: " 2rem auto 0 auto",
    zIndex: 1,
    background: "none",
});

const AllList = ({ searchText }) => {
    document.body.style.overflow = 'auto';
    const [pkgList, setPkgList] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [bookpackageId, setBookPackageId] = useState('');
    // const [packagesData, setPackagesData] = React.useState([]);
    const [transfer, setTransfer] = useState(false);
    const [attraction, setAttraction] = useState(false);
    const [landcombos, setLandcombos] = useState(false);
    const [packages, setPackage] = useState(false);
    const [custom,setCustom]=useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage] = useState(15);

    const packagelist = () => {
        fetch(`${APIPath}/api/v1/agency/bookings`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            method: 'GET',
            mode: "cors",
        })
            .then((res) => res.json())
            .then((data) => {
                setPkgList(data.data);
                // setPackagesData(data.data)
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching Packages list:', error);
                setIsLoading(false);
            });
    }
    useEffect(() => {
        packagelist();
    }, []);


    const indexOfLastItem = currentPage * perPage;
    const indexOfFirstItem = indexOfLastItem - perPage;
    const currentItems = pkgList && pkgList.slice(indexOfFirstItem - (currentPage - 1), indexOfLastItem);
    // console.log(indexOfFirstItem,indexOfLastItem)


    const filterdata = currentItems?.filter(item =>
        item.bookingID?.toLowerCase().includes(searchText.toLowerCase())
    );

    // const filteredPackages = packagesData.filter(item =>
    //     item.agentEmail.toLowerCase().includes(searchText.toLowerCase())
    //   );

    const seeBooking = (id) => {
        setBookPackageId(id)
    }
    const closeBooking = () => {
        setPackage(false)
        setTransfer(false)
        setAttraction(false)
        setLandcombos(false)
        setCustom(false);
        packagelist();
    }

    return <>
        {isLoading ? (
            <div className="loader">

            </div>) : (
            <>
                {pkgList?.length > 0 ? (
                    <>
                        <div className="package-list-container">

                            <table>
                                <thead>
                                    <tr>
                                        <th>Booking Id</th>
                                        <th>Agent Name</th>
                                        <th>Agent Email</th>
                                        <th>Booking Date</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filterdata?.map((val, id) => {
                                        return <>
                                            <tr key={id}>
                                                <td>{val.bookingID}</td>
                                                <td>{val.agentName}</td>
                                                <td>{val.agentEmail}</td>
                                                <td>{val.createdAt.split("T")[0]}</td>
                                                <td>{val.totalCost}</td>
                                                <td><p id={(val.bookingStatus === "Pending" && "pending") ||
                                                    (val.bookingStatus === "On Hold" && "on-hold") ||
                                                    (val.bookingStatus === "Awaiting Payment" && "Awaiting_Payment") ||
                                                    (val.bookingStatus === "Confirmed" && "confirm") ||
                                                    (val.bookingStatus === "Rejected" && "rejected")

                                                }>{val.bookingStatus}</p> </td>
                                                <td>
                                                    <button
                                                        onClick={() => {
                                                            seeBooking(val._id)
                                                            if (val.bookingID.includes('TRF')) {

                                                                setTransfer(true)
                                                            }
                                                            if (val.bookingID.includes('ATT')) {

                                                                setAttraction(true)
                                                            }
                                                            if (val.bookingID.includes('PKG')) {

                                                                setPackage(true)
                                                            }
                                                            if (val.bookingID.includes('LNC')) {

                                                                setLandcombos(true)
                                                            }
                                                            if(val.bookingID.includes('CUS')){
                                                                setCustom(true)
                                                            }
                                                            else {
                                                                console.log("not includes")
                                                            }

                                                        }}
                                                    >
                                                        <MdOutlineRemoveRedEye />
                                                    </button>
                                                </td>
                                            </tr>
                                        </>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </>
                ) : (
                    <div style={{ marginLeft: "1rem" }}>
                        <h2>No Record Found</h2>
                    </div>
                )}
            </>
        )}
        <StyledPaginationContainer>
            <Stack spacing={1}>
                <Pagination
                    count={Math.ceil((pkgList && pkgList.length) / perPage)}
                    page={currentPage}
                    onChange={(event, value) => setCurrentPage(value)}
                    variant="outlined"
                    color="primary"
                    boundaryCount={1}
                />
            </Stack>
        </StyledPaginationContainer>
        {packages && <BookPackageDetails onClose={closeBooking} bookingId={bookpackageId} />}
        {transfer && <TransferBookingDetail
            isOpen={transfer} onClose={closeBooking} id={bookpackageId}
        />}
        {attraction && <BookAttractionDetails onClose={closeBooking} bookingId={bookpackageId} />}
        {landcombos && <BookLandComboDetails onClose={closeBooking} bookingId={bookpackageId} />}
        {custom && <BookCustomDetails onClose={closeBooking} bookingId={bookpackageId} />}
    </>

}
export default AllList;