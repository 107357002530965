import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";
import { GoArrowRight } from "react-icons/go";
import { IoMdClose } from "react-icons/io";
import { PackagegraphAnnually, PackagegraphWeekly, PackagegraphMonthly } from "./Packagegraph";
import { AttractiongraphAnnually, AttractiongraphMonthly, AttractiongraphhWeekly } from "./Attractiongraph";
import { LandcombographAnnually, LandcombographMonthly, LandcombographhWeekly } from "./Landcombograph";
import { TransfergraphAnnually, TransfergraphMonthly, TransfergraphWeekly } from "./Transfergraph";
import './Dashboard.css';
import { Link } from "react-router-dom";
import { APIPath, token } from "../../config/api";

const StyledDashboardContainer = styled(Grid)(({ theme, open }) => ({
  background: "rgba(204,204,204,0.2)",
  // paddingTop: "10px",
  marginTop: "1%",
  marginLeft: open ? theme.spacing(30) : theme.spacing(8),
  width: open ? "80%" : "94%",
  transition: "margin-left 0.3s ease",
}));

const Dashboard = ({ open }) => {
  document.body.style.overflow = 'auto';
  const [totalAgent, setTotalAgent] = useState();
  const [totalBookings, setTotalBookings] = useState();
  const [totalRevenue, setTotalRevenue] = useState();
  const [pendingBookings, setPendingBookings] = useState();
  const [annually, setAnnually] = useState(true);
  const [monthly, setMonthly] = useState(false);
  const [weekly, setWeekly] = useState(false);
  const [custom, setCustom] = useState(false);
  const [graphValue, setGraphValue] = useState('package');

  useEffect(() => {
    fetch(`${APIPath}/api/v1/agency/dashboard/analytics-count`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      method: 'GET',
      mode: 'cors'
    }).then((res) => res.json())
      .then((data) => {
        console.log(data.data)
        setTotalAgent(data.data.totalAgents);
        setTotalBookings(data.data.totalBookings);
        setPendingBookings(data.data.pendingBookings);
        setTotalRevenue(data.data.totalRevenue);
      }).catch((err) => {
        console.log(err);
      })
  }, [])

  const onGraphChange = (e) => {
    setGraphValue(e.target.value);
  }

  return (
    <>
      <StyledDashboardContainer open={open}>
        <div className="dashboard-container">
          <h2>Dashboard</h2>
          <div className="dashboard-all-details">
            <div className="dashboard-agent-parent">
              <div className="dashboard-agent">
                <div className="dashboard-agent-detail">
                  <h4>Total Agents</h4>
                  <h2>{totalAgent}</h2>
                </div>
                <div>
                  <img src="agenticon.svg" height={60} width={60} />
                </div>
              </div>
              <p className="dashboard-inc-text"><img src="/incrementgraph.svg" /><span style={{ color: "rgba(0, 182, 155, 1)" }}>8.5%</span>up from last month</p>
            </div>
            <div className="dashboard-agent-parent">
              <div className="dashboard-agent">
                <div className="dashboard-agent-detail">
                  <h4>Total Bookings</h4>
                  <h2>{totalBookings}</h2>
                </div>
                <div>
                  <img src="bookingicon.svg" height={60} width={60} />
                </div>
              </div>
              <p className="dashboard-inc-text"><img src="/incrementgraph.svg" /><span style={{ color: "rgba(0, 182, 155, 1)" }}>8.5%</span>up from yesterday</p>
            </div>
            <div className="dashboard-agent-parent">
              <div className="dashboard-agent">
                <div className="dashboard-agent-detail">
                  <h4>Total Revenue</h4>
                  <h2> AED {totalRevenue}</h2>
                </div>
                <div>
                  <img src="revenueicon.svg" height={60} width={60} />
                </div>
              </div>
              <p className="dashboard-inc-text"><img src="/incrementgraph.svg" /><span style={{ color: "rgba(0, 182, 155, 1)" }}>8.5%</span>up from yesterday</p>
            </div>
            <div className="dashboard-agent-parent">
              <div className="dashboard-agent">
                <div className="dashboard-agent-detail">
                  <h4>Pending Booking</h4>
                  <h2>{pendingBookings}</h2>
                </div>
                <div>
                  <img src="pendingbooking.svg" height={60} width={60} />
                </div>
              </div>
              <p className="dashboard-inc-text"><img src="/incrementgraph.svg" /><span style={{ color: "rgba(0, 182, 155, 1)" }}>8.5%</span>up from yesterday</p>
            </div>

          </div>
          <div className="dashboard-graph-recent-booking">
            <div className="dashboard-graph">
              <div className="dashboard-graph-header">
                <h2>Sales Analytics</h2>
                <select onChange={(e) => onGraphChange(e)}>
                  <option value="package">All</option>
                  <option value="package">Package</option>
                  <option value="attraction">Attraction</option>
                  <option value="landcombo">Land Combo</option>
                  <option value="transfer">Transfer</option>
                </select>
              </div>
              <div className="dashboard-graph-a-m-w">
                <p className={annually ? "active" : ""}
                  onClick={() => {
                    setAnnually(true)
                    setMonthly(false)
                    setWeekly(false)
                    setCustom(false);
                  }}
                >Annually</p>
                <p
                  className={monthly ? "active" : ""}
                  onClick={() => {
                    setAnnually(false)
                    setMonthly(true)
                    setWeekly(false)
                    setCustom(false);
                  }}
                >Monthly</p>
                <p
                  className={weekly ? "active" : ""}
                  onClick={() => {
                    setAnnually(false)
                    setMonthly(false)
                    setWeekly(true)
                    setCustom(false);
                  }}
                >Weekly</p>
                <p className={custom ? "active" : ""}
                  onClick={() => {
                    setAnnually(false)
                    setMonthly(false)
                    setWeekly(false)
                    setCustom(true);
                  }}
                >
                  Custom
                </p>
                {custom && (
                  <>
                    <div className="dashboard-from-to-date-container">
                      <div className="dashboard-custom-clear">
                        <IoMdClose onClick={() => {
                          setCustom(false);
                        }} />
                      </div>
                      <div className="dashboard-from-to-date">
                        <div className="dashboard-from-date">
                          <h2>From</h2>
                          <input type="date" />
                        </div>
                        <div className="dashboard-to-date">
                          <h2>To</h2>
                          <input type="date" />
                        </div>
                      </div>
                      <div className="dashboard-graph-apply-btn">
                        <button>Apply</button>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="dashboard-graph-container">
                {(graphValue === 'package' && annually) && <PackagegraphAnnually />}
                {(graphValue === 'package' && monthly) && <PackagegraphMonthly />}
                {(graphValue === 'package' && weekly) && <PackagegraphWeekly />}
                {(graphValue === 'attraction' && annually) && <AttractiongraphAnnually />}
                {(graphValue === 'attraction' && monthly) && <AttractiongraphMonthly />}
                {(graphValue === 'attraction' && weekly) && <AttractiongraphhWeekly />}
                {(graphValue === 'landcombo' && annually) && <LandcombographAnnually />}
                {(graphValue === 'landcombo' && monthly) && <LandcombographMonthly />}
                {(graphValue === 'landcombo' && weekly) && <LandcombographhWeekly />}
                {(graphValue === 'transfer' && annually) && <TransfergraphAnnually />}
                {(graphValue === 'transfer' && monthly) && <TransfergraphMonthly />}
                {(graphValue === 'transfer' && weekly) && <TransfergraphWeekly />}

              </div>
            </div>
            <div className="dashboard-recent-booking">
              <h2>Recent Bookings</h2>
              {pendingBookings === 1 && (<>
                <div className="dashboard-booking-pending">
                  <div>
                    <img src="transferpendingicon.svg" />
                  </div>
                  <div>
                    <h4>Transfers</h4>
                    <p>Rahul | 23-02-2024</p>
                  </div>
                  <div>
                    <img src="pending.svg" />
                    <p>| AED 220</p>
                  </div>
                </div>
                <hr style={{ opacity: "0.2" }}></hr>
                <div className="view-all-booking">
                  <Link to="/bookings">
                    <button>view</button>
                  </Link>
                </div>
              </>)}
              {pendingBookings === 2 && (<>
                <div className="dashboard-booking-pending">
                  <div>
                    <img src="transferpendingicon.svg" />
                  </div>
                  <div>
                    <h4>Transfers</h4>
                    <p>Rahul | 23-02-2024</p>
                  </div>
                  <div>
                    <img src="pending.svg" />
                    <p>| AED 220</p>
                  </div>
                </div>
                <hr style={{ opacity: "0.2" }}></hr>
                <div className="dashboard-booking-pending">
                  <div>
                    <img src="transferpendingicon.svg" />
                  </div>
                  <div>
                    <h4>Transfers</h4>
                    <p>Rahul | 23-02-2024</p>
                  </div>
                  <div>
                    <img src="pending.svg" />
                    <p>| AED 220</p>
                  </div>
                </div>
                <div className="view-all-booking">
                  <Link to="/bookings">
                    <button>view</button>
                  </Link>
                </div>
              </>)}
              {pendingBookings > 2 && (<>
                <div className="dashboard-booking-pending">
                  <div>
                    <img src="transferpendingicon.svg" />
                  </div>
                  <div>
                    <h4>Transfers</h4>
                    <p>Rahul | 23-02-2024</p>
                  </div>
                  <div>
                    <img src="pending.svg" />
                    <p>| AED 220</p>
                  </div>
                </div>
                <hr style={{ opacity: "0.2" }}></hr>
                <div className="dashboard-booking-pending">
                  <div>
                    <img src="transferpendingicon.svg" />
                  </div>
                  <div>
                    <h4>Transfers</h4>
                    <p>Rahul | 23-02-2024</p>
                  </div>
                  <div>
                    <img src="pending.svg" />
                    <p>| AED 220</p>
                  </div>
                </div>
                <hr style={{ opacity: "0.2" }}></hr>
                <div className="dashboard-booking-pending">
                  <div>
                    <img src="transferpendingicon.svg" />
                  </div>
                  <div>
                    <h4>Transfers</h4>
                    <p>Rahul | 23-02-2024</p>
                  </div>
                  <div>
                    <img src="pending.svg" />
                    <p>| AED 220</p>
                  </div>
                </div>
                <div className="view-all-booking">
                  <Link to="/bookings">
                    <button>view all</button>
                  </Link>
                </div>
              </>)}
            </div>
          </div>
        </div>
      </StyledDashboardContainer>
    </>
  );
};

export default Dashboard;
