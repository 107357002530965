import React, { useEffect, useState } from "react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import BookAttractionDetails from "./BookAttractionDetails";
import { token ,APIPath} from "../../../config/api";
import './Attraction.css';
import { styled } from "@mui/material/styles";
import {
    Stack,
    Pagination,
} from "@mui/material";

const StyledPaginationContainer = styled("div")({
    background: "#fff",
    margin: " 2rem auto 0 auto",
    zIndex:1,
    background:"none",
});

const AttractionList = ({ searchText }) => {
    document.body.style.overflow = 'auto';
    const [attractionList, setAttractionList] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [bookingDetails, setBookingDetails] = useState(false);
    const [bookAttractionId, setBookAttractionId] = useState('');
    const [attractionData, setAttractionData] = React.useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage] = useState(15);

    const packagelist = () => {
        fetch(`${APIPath}/api/v1/agency/bookings/attractions`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            method: 'GET',
            mode: "cors",
        })
            .then((res) => res.json())
            .then((data) => {
                setAttractionList(data.data);
                setAttractionData(data.data)
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching vehicle list:', error);
                setIsLoading(false);
            });
    }
    useEffect(() => {
        packagelist();
    }, [])

    const indexOfLastItem = currentPage * perPage;
    const indexOfFirstItem = indexOfLastItem - perPage;
    const currentItems = attractionList && attractionList.slice(indexOfFirstItem, indexOfLastItem);

    const filterdata = currentItems?.filter(item =>
        item.bookingID.toLowerCase().includes(searchText.toLowerCase())
      );
    // const filteredLandCombo = attractionData.filter(item =>
    //     item.agentEmail.toLowerCase().includes(searchText.toLowerCase())
    // );

    const seeBooking = (id) => {
        setBookingDetails(true);
        setBookAttractionId(id)
    }
    const closeBooking = () => {
        setBookingDetails(false);
        packagelist();
    }


    return <>
        {isLoading ? (
            <div className="loader">

            </div>) : (
            <>
                {attractionList?.length > 0 ? (
                    <>
                        <div className="package-list-container">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Booking Id</th>
                                        <th>Agent Name</th>
                                        <th>Agent Email</th>
                                        <th>Booking Date</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filterdata?.map((val, id) => {
                                        return <>
                                            <tr>
                                                <td>{val.bookingID}</td>
                                                <td>{val.agentName}</td>
                                                <td>{val.agentEmail}</td>
                                                <td>{val.createdAt.split("T")[0]}</td>
                                                <td>{val.totalCost}</td>
                                                <td><p id={(val.bookingStatus === "Pending" && "pending") ||
                                                    (val.bookingStatus === "On Hold" && "on-hold") ||
                                                    (val.bookingStatus === "Awaiting Payment" && "Awaiting_Payment") ||
                                                    (val.bookingStatus === "Confirmed" && "confirm") ||
                                                    (val.bookingStatus === "Rejected" && "rejected")
                                                }>{val.bookingStatus}</p></td>
                                                <td>
                                                    <button onClick={() => {
                                                        seeBooking(val._id)
                                                    }}>
                                                        <MdOutlineRemoveRedEye />
                                                    </button>
                                                </td>
                                            </tr>
                                        </>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </>
                ) : (
                    <div style={{ marginLeft: "1rem" }}>
                        <h2>No Record Found</h2>
                    </div>
                )}
            </>
        )}
        {bookingDetails && <BookAttractionDetails onClose={closeBooking} bookingId={bookAttractionId} />}
        <StyledPaginationContainer>
                    <Stack spacing={1}>
                        <Pagination
                            count={Math.ceil((attractionList && attractionList.length) / perPage)}
                            // count={1}
                            page={currentPage}
                            onChange={(event, value) => setCurrentPage(value)}
                            variant="outlined"
                            color="primary"
                            boundaryCount={1}
                        />
                    </Stack>
        </StyledPaginationContainer>
    </>

}
export default AttractionList;