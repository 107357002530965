import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "member", headerName: "Member", width: 130 },
  { field: "date", headerName: "Date", width: 100 },
  {
    field: "paymentType",
    headerName: "Payment Type",
    type: "number",
    width: 100,
  },
  {
    field: "paymentDetails",
    headerName: "Payment Details",
    type: "number",
    width: 150,
  },
  {
    field: "amount",
    headerName: "Amount",
    type: "number",
    width: 130,
  },
  {
    field: "status",
    headerName: "Status",
    type: "number",
    width: 120,
    renderCell: (params) => (
      <Tooltip title={params.row.status} placement="top">
        <div style={{ display: "flex", alignItems: "center", marginLeft: 8 }}>
          <div
            style={{
              width: 10,
              height: 10,
              borderRadius: "50%",
              backgroundColor: getStatusColor(params.row.status),
              marginRight: 8,
            }}
          ></div>
        </div>
      </Tooltip>
    ),
  },
];

const rows = [
  {
    id: 1,
    member: "John",
    date: "01-15-2023",
    paymentType: "Credit Card",
    paymentDetails: "XXXX-1234",
    amount: 1500,
    status: "pending",
  },
  {
    id: 2,
    member: "Alice",
    date: "02-28-2023",
    paymentType: "PayPal",
    paymentDetails: "alice@example.com",
    amount: 2500,
    status: "failed",
  },
  {
    id: 3,
    member: "Bob",
    date: "03-10-2023",
    paymentType: "Google Pay",
    paymentDetails: "bob@gmail.com",
    amount: 1800,
    status: "paid",
  },
  {
    id: 4,
    member: "Eva",
    date: "04-05-2023",
    paymentType: "Credit Card",
    paymentDetails: "XXXX-5678",
    amount: 1200,
    status: "pending",
  },
  {
    id: 5,
    member: "David",
    date: "05-20-2023",
    paymentType: "Bank Transfer",
    paymentDetails: "Account: 12345678",
    amount: 3000,
    status: "failed",
  },
  {
    id: 6,
    member: "Sophie",
    date: "06-08-2023",
    paymentType: "PayPal",
    paymentDetails: "sophie@example.com",
    amount: 2000,
    status: "pending",
  },
  {
    id: 7,
    member: "Michael",
    date: "07-15-2023",
    paymentType: "Credit Card",
    paymentDetails: "XXXX-9876",
    amount: 1800,
    status: "paid",
  },
  {
    id: 8,
    member: "Emma",
    date: "08-02-2023",
    paymentType: "Google Pay",
    paymentDetails: "emma@gmail.com",
    amount: 2500,
    status: "failed",
  },
  {
    id: 9,
    member: "Daniel",
    date: "09-18-2023",
    paymentType: "Bank Transfer",
    paymentDetails: "Account: 87654321",
    amount: 3000,
    status: "pending",
  },
  {
    id: 10,
    member: "Olivia",
    date: "10-25-2023",
    paymentType: "Credit Card",
    paymentDetails: "XXXX-2468",
    amount: 1500,
    status: "paid",
  },
  {
    id: 11,
    member: "Liam",
    date: "11-11-2023",
    paymentType: "PayPal",
    paymentDetails: "liam@example.com",
    amount: 1800,
    status: "failed",
  },
  {
    id: 12,
    member: "Ava",
    date: "12-30-2023",
    paymentType: "Google Pay",
    paymentDetails: "ava@gmail.com",
    amount: 2500,
    status: "pending",
  },
  {
    id: 13,
    member: "Mia",
    date: "01-08-2024",
    paymentType: "Credit Card",
    paymentDetails: "XXXX-1357",
    amount: 1200,
    status: "failed",
  },
  {
    id: 14,
    member: "Noah",
    date: "02-14-2024",
    paymentType: "Bank Transfer",
    paymentDetails: "Account: 11112222",
    amount: 3000,
    status: "paid",
  },
  {
    id: 15,
    member: "Isabella",
    date: "03-22-2024",
    paymentType: "PayPal",
    paymentDetails: "isabella@example.com",
    amount: 1800,
    status: "pending",
  },
  {
    id: 16,
    member: "Jackson",
    date: "04-30-2024",
    paymentType: "Credit Card",
    paymentDetails: "XXXX-3690",
    amount: 1500,
    status: "failed",
  },
  {
    id: 17,
    member: "Sophia",
    date: "05-17-2024",
    paymentType: "Google Pay",
    paymentDetails: "sophia@gmail.com",
    amount: 2500,
    status: "paid",
  },
  {
    id: 18,
    member: "Ethan",
    date: "06-26-2024",
    paymentType: "Bank Transfer",
    paymentDetails: "Account: 33334444",
    amount: 3000,
    status: "failed",
  },
  {
    id: 19,
    member: "Aiden",
    date: "07-04-2024",
    paymentType: "PayPal",
    paymentDetails: "aiden@example.com",
    amount: 1800,
    status: "pending",
  },
  {
    id: 20,
    member: "Madison",
    date: "08-12-2024",
    paymentType: "Credit Card",
    paymentDetails: "XXXX-8024",
    amount: 1200,
    status: "paid",
  },
  {
    id: 21,
    member: "Liam",
    date: "09-20-2024",
    paymentType: "Google Pay",
    paymentDetails: "liam@gmail.com",
    amount: 2500,
    status: "failed",
  },
  {
    id: 22,
    member: "Emma",
    date: "10-27-2024",
    paymentType: "Bank Transfer",
    paymentDetails: "Account: 55556666",
    amount: 3000,
    status: "pending",
  },
  {
    id: 23,
    member: "Oliver",
    date: "11-05-2024",
    paymentType: "PayPal",
    paymentDetails: "oliver@example.com",
    amount: 1800,
    status: "failed",
  },
  {
    id: 24,
    member: "Ava",
    date: "12-13-2024",
    paymentType: "Credit Card",
    paymentDetails: "XXXX-4680",
    amount: 1500,
    status: "paid",
  },
  {
    id: 25,
    member: "Sophie",
    date: "01-21-2025",
    paymentType: "Google Pay",
    paymentDetails: "sophie@gmail.com",
    amount: 2500,
    status: "paid",
  },
  {
    id: 26,
    member: "Elijah",
    date: "02-28-2025",
    paymentType: "Bank Transfer",
    paymentDetails: "Account: 77778888",
    amount: 3000,
    status: "failed",
  },
  {
    id: 27,
    member: "Mia",
    date: "03-07-2025",
    paymentType: "PayPal",
    paymentDetails: "mia@example.com",
    amount: 1800,
    status: "pending",
  },
  {
    id: 28,
    member: "Lucas",
    date: "04-15-2025",
    paymentType: "Credit Card",
    paymentDetails: "XXXX-5792",
    amount: 1200,
    status: "failed",
  },
  {
    id: 29,
    member: "Olivia",
    date: "05-23-2025",
    paymentType: "Google Pay",
    paymentDetails: "olivia@gmail.com",
    amount: 2500,
    status: "pending",
  },
  {
    id: 30,
    member: "Logan",
    date: "06-01-2025",
    paymentType: "Bank Transfer",
    paymentDetails: "Account: 99990000",
    amount: 3000,
    status: "paid",
  },
];

const getStatusColor = (status) => {
  switch (status) {
    case "paid":
      return "green";
    case "failed":
      return "red";
    case "pending":
      return "orange";
    default:
      return "black";
  }
};

export default function DataTable() {
  return (
    <div style={{ height: 500, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        checkboxSelection
        components={{
          NoRowsOverlay: () => "No payments available",
        }}
        style={{ border: "1px solid #ccc", borderRadius: "5px" }}
      />
    </div>
  );
}
