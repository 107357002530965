import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { APIPath, token } from "../../../config/api";
import axios from 'axios';

const BookLandComboDetails = ({ onClose, bookingId }) => {
    document.body.style.overflow = 'hidden';
    const [bookingDetails, setBookingDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [bookingStatus, setBookingStatus] = useState('')
    const [gatewayType, setGatewayType] = useState("STRIPE");
    const [rejection, setRejection] = useState(false);
    const [rejectionText, setRejectionText] = useState("");
    const [onhold, setOnhold] = useState(false);
    const [onholdText, setOnholdText] = useState("");
    const [status, setStatus] = useState('');

    useEffect(() => {
        fetch(`${APIPath}/api/v1/agency/bookings/landCombos?id=${bookingId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            method: 'GET',
            mode: "cors",
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data.data)
                setBookingDetails(data.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching vehicle list:', error);
                setIsLoading(false);
            });
    }, [bookingId])

    const sendPaymentLink = () => {
        fetch(
            `${APIPath}/api/v1/agency/payments/transfer/payment-link?id=${bookingId}&gatewayType=${gatewayType}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                method: "POST",
                mode: "cors",
            }
        )
            .then((res) => res.json())
            .then((data) => {
                console.log(data.statusCode);
                if (data.statusCode === 200) {
                    alert("Payment Link Successfully sent to Agent...");
                    onClose();
                } else {
                    alert("Something Went wrong, Please try again!!");
                    onClose();
                }
            })
            .catch((err) => {
                alert(err);
            });
    }
    const reasonClose = () => {
        setRejection(false);
        setOnhold(false)
        setStatus('');
    }
    const rejectRequest = () => {
        // console.log("Request Rejected ")
        setRejection(true)
        setStatus("Rejected");
    }
    const reasonMsg = {
        status: status,
        reason: status === "Rejected" ? rejectionText : onholdText,
    }
    const rejectBooking = () => {
        if (rejectionText.length > 5) {
            axios.patch(`${APIPath}/api/v1/agency/bookings/landCombos?id=${bookingId}`, reasonMsg, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then((response) => {
                    console.log(response.data);
                    if (response.status === 200) {
                        alert("You are rejecting this Request");
                        setRejectionText("");
                        reasonClose();
                        onClose()
                    }
                })
                .catch((error) => {
                    console.error('Error While Rejecting:', error);
                });
        } else {
            alert("please write Rejection reason");
            return;
        }
    }
    const onHoldRequest = () => {
        console.log("request On Hold");
        setOnhold(true)
        setStatus("On Hold")
    }

    const onHoldBooking = () => {
        if (onholdText.length > 5) {
            axios.patch(`${APIPath}/api/v1/agency/bookings/landCombos?id=${bookingId}`, reasonMsg, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then((response) => {
                    console.log(response.data);
                    if (response.status === 200) {
                        alert("You are Putting this request 'On Hold'.");
                        setOnholdText("");
                        reasonClose();
                        onClose();
                    }
                })
                .catch((error) => {
                    console.error('Error While put On Hold:', error);
                });
        } else {
            alert("please write on hold reason");
            return;
        }
    }


    return <>
        <div className="booking-details-container">
            <div className="booking-details-page">
                {isLoading ? (
                    <div className="loader">

                    </div>) : (
                    <>
                        <div className="booking-pkg-pass-header">
                            <h4>Booking Details</h4>
                            <h2 onClick={onClose}><IoMdClose /></h2>
                        </div>
                        <div className="booking-pkg-package-details">
                            <h2 style={{ color: "rgb(14 76 237)" }}>Land Combo Name:</h2>
                            <h2>&nbsp;{bookingDetails[0]?.landCombos[0].title}</h2>
                        </div>
                        <hr />
                        <div className="booking-pkg-pass-details">
                            <div className="booking-pkg-pass-name">
                                <h4>Customer Name</h4>
                                <p>{bookingDetails[0].customerDetails.name}</p>
                            </div>
                            <div className="booking-pkg-pass-name">
                                <h4>Customer Mobile No.</h4>
                                <p>+{bookingDetails[0].customerDetails.phone}</p>
                            </div>
                            <div className="booking-pkg-pass-name">
                                <h4>Customer Email.</h4>
                                <p>{bookingDetails[0].customerDetails.email}</p>
                            </div>
                        </div>
                        <hr />
                        <div className="booking-pkg-pass-details">
                            <div className="booking-pkg-pass-name">
                                <h4>Adults</h4>
                                <p>{bookingDetails[0]?.landCombos[0].numberOfAdults}</p>
                            </div>
                            <div className="booking-pkg-pass-name">
                                <h4>Children</h4>
                                <p>{bookingDetails[0]?.landCombos[0].numberOfChildrens}</p>
                            </div>

                            <div className="booking-pkg-pass-name">
                                <h4>Start Date</h4>
                                <p>{bookingDetails[0]?.landCombos[0].startDate?.split("T")[0]}</p>
                            </div>
                        </div>
                        <hr />
                        <div className="booking-pkg-agent-details">
                            <div className="booking-pkg-agent-name">
                                <h4>Agent Name</h4>
                                <p>{bookingDetails[0].agentName}</p>
                            </div>
                            <div className="booking-pkg-agent-name">
                                <h4>Mobile No.</h4>
                                <p>+{bookingDetails[0].agentPhoneNumber}</p>
                            </div>
                            <div className="booking-pkg-agent-name">
                                <h4>Email</h4>
                                <p>{bookingDetails[0].agentEmail}</p>
                            </div>

                        </div>
                        <hr />
                        <div className="choose-paymentgatway" style={{ padding: "1rem 2rem" }} >
                            <h4>Select Payment Gateway</h4>
                            <select className="payment-select"
                                onChange={(e) => {
                                    setGatewayType(e.target.value)
                                }}>
                                <option disabled >Select Gateway</option>
                                <option value="STRIPE"> STRIPE</option>
                                <option value="TELR">TELR</option>
                            </select>
                        </div>
                        <div className="booking-pkg-price-and-actions">
                            <div className="booking-pkg-price">
                                <h2><span>Total cost:AED&nbsp;</span><b>{bookingDetails[0].totalCost}</b>
                                    {/* <sub style={{ fontSize: "12px" }}> +VAT(5%)</sub> */}
                                </h2>
                            </div>
                            <div className="booking-pkg-actions">
                                <button id="reject"
                                    onClick={rejectRequest}
                                >Reject</button>
                                <button id="on-hold"
                                    onClick={onHoldRequest}>Hold</button>
                                <button id="send-link"
                                    onClick={sendPaymentLink}
                                >Send payment Link</button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
        {rejection && (
            <>
                <div className="transfer-reason-parent">
                    <div className="transfer-raeson">
                        <div className="reason-topbar">
                            <p>Reason for Rejection</p>
                            <p id="cencel" onClick={reasonClose} > <IoMdClose /> </p>
                        </div>
                        <div className="reason-details">
                            <textarea
                                placeholder="Write Reason..."
                                value={rejectionText}
                                onChange={(e) => {
                                    setRejectionText(e.target.value);
                                }}
                            />
                        </div>
                        <div className="reason-button">
                            <button
                                onClick={() => {
                                    rejectBooking();
                                }}
                            >
                                Reject
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )}
        {onhold && (
            <>
                <div className="transfer-reason-parent">
                    <div className="transfer-raeson">
                        <div className="reason-topbar">
                            <p>Reason for On Hold</p>
                            <p id="cencel" onClick={reasonClose}> <IoMdClose /></p>
                        </div>
                        <div className="reason-details">
                            <textarea
                                placeholder="Write Reason..."
                                value={onholdText}
                                onChange={(e) => {
                                    setOnholdText(e.target.value);
                                }}
                            />
                        </div>
                        <div className="reason-button">
                            <button
                                onClick={() => {
                                    onHoldBooking();
                                }}
                            >
                                On Hold
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )}
    </>
}
export default BookLandComboDetails;