import React from "react";
import './Success_failure.css'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
const Success=()=>{
    return<>
         <div style={{display:"block", marginLeft:"330px"}}>
             <div className="success">
                <div className="success-block">
                    <CheckCircleIcon style={{ color: 'green',fontSize:'4rem' }}/>
                    <h2> Payment Done! </h2>
                    <p> Thank you for comleting secure online payment! </p>
                    <h4> Have a great day! </h4>
                    <button> Go Back </button>
                </div>
             </div>
         </div>
      
    </>
}
export default Success;