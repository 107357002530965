const API_BASE_URL = "https://admin.magicalvacation.com/api/v1";

const fetchAttractions = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/attractions`);
    if (!response.ok) {
      throw new Error("Failed to fetch attractions");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching attractions:", error.message);
    throw error;
  }
};

export { fetchAttractions };
