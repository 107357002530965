import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";
import { MdEdit } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { APIPath, token } from "../../config/api";
import './AgentMarkup.css';
import AddMarkupProduct from "./AddMarkupProduct";

const StyledDashboardContainer = styled(Grid)(({ theme, open }) => ({
    background: "rgba(204,204,204,0.2)",
    // marginTop: "1%",
    marginLeft: open ? theme.spacing(30) : theme.spacing(8),
    width: open ? "80%" : "94%",
    transition: "margin-left 0.3s ease",
    height: "100vh",
}));

const AgentMarkup = ({ open }) => {
    const [markupForm, SetMarkupForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [productList, setProductList] = useState(null);
    // const [editedProduct, setEditedProduct] = useState(false);
    const [productId,setProductId]=useState("")
    const [productName, setProductName] = useState("");
    const [b2bMarkup, setb2bMarkup] = useState();
    const [b2cMarkup, setb2cMarkup] = useState();

    const getproductWithMarkup = () => {
        setIsLoading(true)
        fetch(`${APIPath}/api/v1/agency/markup`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            method: 'GET',
            mode: 'cors',
        })
            .then((res) => res.json())
            .then((data) => {
                setIsLoading(false);
                console.log(data.data);
                setProductList(data.data)
            })
            .catch((err) => {
                alert(err)
            })
    }
    useEffect(() => {
        getproductWithMarkup();
    }, [])
    const onClose = () => {
        getproductWithMarkup();
        SetMarkupForm(false);
    }

    return <StyledDashboardContainer open={open}>
        <div style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "2rem"
        }}>
            <h2 style={{ fontSize: "18px", color: "#00081d", fontWeight: "500", padding: "10px" }}>Agents Markup</h2>
            <button style={{
                border: "none",
                borderRadius: "20px",
                padding: "5px 15px",
                outline: "none",
                background: "rgba(46, 144, 250, 1)",
                color: "#fff",
                height: "fit-content",
                cursor: "pointer"
            }}
                onClick={() => {
                    setProductName("");
                    setb2bMarkup();
                    setb2cMarkup()
                    SetMarkupForm(true);
                }}
            >Add Product</button>
        </div>
        {isLoading ? (<div className="loader"></div>) :
            <div className="agent-table">
                <table>
                    <thead>
                        <tr>
                            <td>Sl no.</td>
                            <td>Product Name</td>
                            <td>B2B Markup (in %)</td>
                            <td>B2C Markup (in %)</td>
                            <td>Actions</td>
                        </tr>
                    </thead>
                    <tbody>
                        {productList?.map((val, id) => {
                            return <tr key={id}>
                                <td>{id + 1}</td>
                                <td>{val.productName}</td>
                                <td>{val.b2bMarkup}</td>
                                <td>{val.b2cMarkup}</td>
                                <td className="agent-markup-btn">
                                    <button onClick={() => {
                                        setProductId(val._id)
                                        setProductName(val.productName);
                                        setb2bMarkup(val.b2bMarkup);
                                        setb2cMarkup(val.b2cMarkup)
                                        // if(productName.length > 0 && b2bMarkup.length > 0 && b2cMarkup.length > 0){
                                        SetMarkupForm(true)
                                        // }
                                    }}><MdEdit /> </button>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        }
        {markupForm && <AddMarkupProduct onClose={onClose} Id={productId}
            productName={productName} b2bMarkup={b2bMarkup} b2cMarkup={b2cMarkup}
        />}
    </StyledDashboardContainer>
};

export default AgentMarkup;
