// Login.jsx
import React, { useState } from 'react';
import styled from 'styled-components';
import Logo from "../../assets/logo/MV_LOGO.svg";
import { login } from '../../store/authSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './Login.css';
const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const LogoContainer = styled.div`
  margin-bottom: 20px;
`;

const LogoImage = styled.img`
  width: 100px; // Adjust the size as needed
  height: auto;
`;

const LoginForm = styled.form`
  width: 300px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const InputField = styled.input`
  width: 92%;
  padding: 10px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const users = [
  { id: 1, username: 'user1', password: 'password1' },
  { id: 2, username: 'user2', password: 'password2' },
  { id: 3, username: 'user3', password: 'password3' },
  { id: 4, username: 'user4', password: 'password4' },
  { id: 5, username: 'user5', password: 'password5' },
];

const Login = () => {
  // console.log("Loginnnnnnnnnnnnn")
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Check if the provided credentials match any user
    const user = users.find(u => u.username === username && u.password === password);
    if (user) {
      // Simulate successful login
      dispatch(login({ username, password }));
      navigate("/dashboard")
    } else {
      setError('Invalid username or password');
    }
  };

  return (
    <LoginContainer
      style={{
        background: "url('/loginBanner.jpg')",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}>
      <div className='login-container' >
        <div>
          <img src={Logo} width={200} />
        </div>
        <LoginForm onSubmit={handleSubmit} id='login-form'>
          <InputField id='login-form-input'
            type="text"
            placeholder="Username" 
            value={username} 
            onChange={(e) => setUsername(e.target.value)} 
          />
          <InputField id='login-form-input'
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <SubmitButton type="submit" id='login-form-btn'>Sign In</SubmitButton>
        </LoginForm>
        <p style={{ color: 'red' }}>{error}</p>
      </div>
     
    </LoginContainer>
  );
};

export default Login;
