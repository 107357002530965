import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";
import './Agent.css';
import { IoMdClose } from "react-icons/io";
import { APIPath, token } from "../../config/api";

const StyledDashboardContainer = styled(Grid)(({ theme, open }) => ({
    background: "rgba(204,204,204,0.2)",
    marginTop: "1%",
    marginLeft: open ? theme.spacing(30) : theme.spacing(8),
    width: open ? "80%" : "94%",
    transition: "margin-left 0.3s ease",
    height: "100vh",
}));

const AgentDetails = ({ open, close, agentId, agentName,
    agentEmail,agentMob,agentUserId,agentCity,totalBooking,vatNumber,ownerName,totalRevenue }) => {

    document.body.style.overflow='hidden';

    const deleteAgent=()=>{
      let confirm= window.confirm("Are you sure to delete this agent? ")
      if(confirm){
        console.log("Agent Deleted")
        alert("Agent deleted")
      }
      else {
        console.log("Agent not deleted");
        return
      }
    }


    return (
        <>
            <StyledDashboardContainer open={open}>
                <div className="booking-details-container">

                    <div className="booking-details-page">
                        <div className="booking-pkg-pass-header">
                            <h4>Agent Details</h4>
                            <h2 onClick={close}><IoMdClose /></h2>
                        </div>
                        <div className="agent-img-and-details">
                            <div className="agent-image">
                                <img src="agent_pic.svg" alt="User_Img" />
                            </div>
                            <div className="agent-details">
                                <div className="agent-sub-details">
                                    <h4>User Id</h4>
                                    <h3>{agentUserId}</h3>
                                </div>
                                <div className="agent-sub-details">
                                    <h4>Name</h4>
                                    <h3>{agentName}</h3>
                                </div>
                                <div className="agent-sub-details">
                                    <h4>Email</h4>
                                    <h3>{agentEmail}</h3>
                                </div>
                                <div className="agent-sub-details">
                                    <h4>Phone Number</h4>
                                    <h3>+{agentMob}</h3>
                                </div>
                                <div className="agent-sub-details">
                                    <h4>Location</h4>
                                    <h3>{agentCity}</h3>
                                </div>
                                <div className="agent-sub-details">
                                    <h4>Owner Name</h4>
                                    <h3>{ownerName}</h3>
                                </div>
                                <div className="agent-sub-details">
                                    <h4>Website URL</h4>
                                    <h3>www.magicalvacation.com</h3>
                                </div>
                            </div>
                        </div>
                        <div className="agent-sales-records">
                            <h2>Sales Record</h2>
                            <br/>
                            <div className="agent-details">
                                <div className="agent-sub-details">
                                    <h4>Total Bookings</h4>
                                    <h3>{totalBooking}</h3>
                                </div>
                                <div className="agent-sub-details">
                                    <h4>Revenue</h4>
                                    {totalRevenue !== undefined ? (
                                    <h3>AED {totalRevenue}</h3>
                                    ):(
                                        <h3>AED 0</h3>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="agent-delete-btn">
                             <button 
                             onClick={()=>{
                                deleteAgent();
                             }}
                             >
                                <img src="deleteicon.svg"/>
                                Delete
                             </button>
                        </div>

                    </div>
                </div>
            </StyledDashboardContainer>
        </>
    );
};

export default AgentDetails;
