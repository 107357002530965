
import * as React from "react";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import { Paper, } from "@mui/material";
import { useState, useEffect } from "react";
import { APIPath, token } from "../../config/api";
import "./Hotel.css";

const StyledTableContainer = styled(TableContainer)(({ theme, open }) => ({
    width: open ? "80%" : "94%",
    transition: "width 0.3s ease",
    paddingTop: "7px",
    marginLeft: open ? "243px" : "69px",
    position: "relative",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    overflow: "hidden",
    marginBottom: "20px",
    boxShadow: "none",
}));

const HotelForm = ({ open, onClose,countryIdT,countryNameT,cityIdT,cityNameT,zoneIdT,zoneNameT}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [country,setCountry]=useState(null)
    const [countryName, setCountryName] = useState(countryNameT || "");
    const [countryId, setCountryId] = useState(countryIdT || "");
    const [city, setCity] = useState(null);
    const [cityName,setCityName]=useState(cityNameT || "")
    const [cityId, setCityId] = useState(cityIdT || "");
    const [zone,setZone]=useState(null);
    const [zoneId,setZoneId]=useState(zoneIdT || "");
    const [zoneName,setZoneName]=useState(zoneNameT || "");
    const [hoteltName, setHotelName] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [address,setAddress]=useState('');
    const [website, setWebsite] = useState('');
    const [image, setImage] = useState(null);
/////--------------Country
    useEffect(() => {
        fetch(`${APIPath}/api/v1/agency/country`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            method: 'GET',
            mode: 'cors',
        })
            .then((res) => res.json())
            .then((data) => {
                setCountry(data.data);
            })
            .catch((err) => {
                alert(err)
            })
    }, [])
/////--------------City
    useEffect(() => {
        fetch(`${APIPath}/api/v1/agency/city?country=${countryId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            method: 'GET',
            mode: 'cors',
        })
            .then((res) => res.json())
            .then((data) => {
                setCity(data.data);
            })
            .catch((err) => {
                alert(err)
            })
    }, [countryId]);
/////--------------Zone
    useEffect(() => {
        fetch(`${APIPath}/api/v1/agency/zone?city=${cityId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            method: 'GET',
            mode: 'cors',
        })
            .then((res) => res.json())
            .then((data) => {
                setZone(data.data);
            })
            .catch((err) => {
                alert(err)
            })
    }, [cityId])

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    const hotelObj = 
        {
            name: hoteltName,
            location: {
              address:address ,
              cityId: cityId,
              countryId:countryId,
              zipCode: zipCode,
              zoneId:zoneId
            },
            contact: {
              website: website
            }
    }

    const AddHotel = () => {
        sendDataToServer(hotelObj, image);
    };
    const sendDataToServer = async (hotelObj, image) => {
        try {
            showLoader();
            const formData = new FormData();
            formData.append('jsonData', JSON.stringify(hotelObj));
            formData.append('image', image);
            const response = await fetch(`${APIPath}/api/v1/agency/hotels`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                method: 'POST',
                mode: 'cors',
                body: formData
            });

            hideLoader();
            if (!response.ok) {
                console.log(response);
                alert("Failed to add Hotel");
                return;
            }
            alert("New Hotel added");
            const responseData = await response.json();
           onClose();
        } catch (error) {
            console.error('Error sending data to server:', error);
            hideLoader();
            alert('An error occurred while adding the Hotel');
        }
    };

    const showLoader = () => {
        setIsLoading(true);
    };

    const hideLoader = () => {
        setIsLoading(false);
    };


    return (
        <StyledTableContainer component={Paper} open={open}>
            <div className="add-country-container">
                <div className="add-country-form" style={{width:"50%"}}>
                    <form className="add-country" onSubmit={(e) => {
                        e.preventDefault();
                        AddHotel();
                    }}>
                        <h2>Add Hotel</h2>
                        <div className="">
                            <div className="country-city">
                                <div className="add-country-name" style={{width:"100%"}}>
                                    <label>Select Country *</label>
                                    <select value={countryId} onChange={(e) => setCountryId(e.target.value)}
                                    required
                                    >
                                        <option value="" disabled>Select Country</option>
                                        {country?.map((country, id) => (
                                            <option key={country._id} value={country._id}>
                                                {country.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="add-country-name" style={{width:"100%"}}>
                                    <label>Select City *</label>
                                    <select value={cityId} onChange={(e) => setCityId(e.target.value)}
                                    required
                                    >
                                        <option value="" disabled>Select City</option>
                                        {city?.map((city, id) => (
                                            <option key={city._id} value={city._id}>
                                                {city.name}
                                            </option>
                                        ))}
                                        
                                    </select>
                                </div>
                                <div className="add-country-name" style={{width:"100%"}}>
                                    <label>Select Zone *</label>
                                    <select value={zoneId} onChange={(e) => {
                                        setZoneId(e.target.value);
                                        console.log(e.target.value)
                                    }}
                                    required
                                    >
                                        <option value="" disabled>Select Zone</option>
                                        {zone?.map((zone, id) => (
                                            <option key={zone._id} value={zone._id}>
                                                {zone.name}
                                            </option>
                                        ))}
                                        
                                    </select>
                                </div>
                            </div>
                            <br />
                            <div className="add-country-name">
                                <label>Hotel Name *</label>
                                <input
                                    type="text"
                                    placeholder="Type Hotel name..."
                                    name="name"
                                    value={hoteltName}
                                    maxLength={30}
                                    onChange={(e) => {
                                        setHotelName(e.target.value);
                                    }}
                                    required
                                />
                            </div>
                            <br />
                            <div className="country-city">
                                <div className="add-country-name">
                                    <label>Zip Code (optional) </label>
                                    <input
                                        type="text"
                                        placeholder="Type Zip code..."
                                        name="name"
                                        value={zipCode}
                                        maxLength={6}
                                        onChange={(e) => {
                                            setZipCode(e.target.value);
                                        }}
                                        
                                    />
                                </div>
                                <div className="add-country-name">
                                    <label>Hotel Website (optional) </label>
                                    <input
                                        type="text"
                                        placeholder="Enter hotel website"
                                        name="name"
                                        value={website}
                                        maxLength={40}
                                        onChange={(e) => {
                                            setWebsite(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <br />
                            <div className="add-country-name">
                                <label> Choose Image of Hotel *</label>
                                <input className="file-input"
                                    placeholder="Choose image"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                    required
                                />
                            </div>
                            <br />
                            <div className="add-country-name">
                                <label>Hotel Address (optional) </label>
                                <input
                                    type="text"
                                    placeholder="Write Hotel address..."
                                    name="name"
                                    value={address}
                                    maxLength={50}
                                    onChange={(e) => {
                                        setAddress(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="add-country-btn">
                            {isLoading ? (
                                <div className="loader"></div>
                            ) : (
                                <>
                                    <button onClick={onClose} id="cancel">Cancel</button>
                                    <button type="submit" id="addvehicle" >Add Hotel</button>
                                </>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </StyledTableContainer>

    );
}

export default HotelForm;