import React from "react";
import './Success_failure.css'
import AddCircleIcon from '@mui/icons-material/AddCircle';
const Failure=()=>{
    return<>
         <div style={{display:"block", marginLeft:"330px"}}>
             <div className="success">
                <div className="fail-block">
                    <AddCircleIcon style={{ color: 'red', fontSize: '4rem', transform: "rotate(45deg)" }}/>
                    <h2> Payment Fail </h2>
                    <p> The payment was unsuccessful due to an abnormility.
                        <br/>please try again or use another payment method</p>
                    {/* <h4> Have a great day! </h4> */}
                    <button> Try Again </button>
                </div>
             </div>
         </div>
      
    </>
}
export default Failure;