import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";
import { TbSearch } from "react-icons/tb";
import {
    Stack,
    Pagination,
} from "@mui/material";
import './Agent.css';
import * as XLSX from 'xlsx';
import { APIPath, token } from "../../config/api";
import AgentDetails from "./AgentDetails";

const StyledDashboardContainer = styled(Grid)(({ theme, open }) => ({
    background: "rgba(204,204,204,0.2)",
    // marginTop: "1%",
    marginLeft: open ? theme.spacing(30) : theme.spacing(8),
    width: open ? "80%" : "94%",
    transition: "margin-left 0.3s ease",
    height: "100vh",
}));
const StyledPaginationContainer = styled("div")({
    background: "#fff",
    margin: " 2rem auto 0 auto",
    zIndex:1,
    background:"none",
    display:"flex",
    justifyContent:"center"
});
const Agent = ({ open }) => {
    document.body.style.overflow='auto';
    const [searchText, setSearchText] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [agentList, setAgentList] = useState(null);
    const [agent,setAgent]=useState(false);
    const [agentid,setAgentid]=useState('');
    const [agentName,setAgentName]=useState('');
    const [agentEmail,setAgentEmail]=useState('');
    const [agentUserId,setAgentUserId]=useState('');
    const [totalBooking,setTotalBooking]=useState();
    const [totalRevenue,setTotalRevenue]=useState(0);
    const [vatNumber,setVatNumber]=useState('');
    const [agentMob,setAgentMob]=useState();
    const [agentCity,setAgentCity]=useState();
    const [ownerName,setOwnerName]=useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage] = useState(15);
    

    const getAgentList = () => {
        fetch(`${APIPath}/api/v1/agency/agents`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            method: 'GET',
            mode: "cors",
        })
            .then((res) => res.json())
            .then((data) => {
                setAgentList(data.data);
                setFilterdata(data.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching Packages list:', error);
                setIsLoading(false);
            });
    }

    useEffect(() => {
        getAgentList();
    }, [])

    const close=()=>{
        setAgent(false);
    }
    const indexOfLastItem = currentPage * perPage;
    const indexOfFirstItem = indexOfLastItem - perPage;
    const currentItems = agentList && agentList?.slice(indexOfFirstItem, indexOfLastItem);

    const filterdata = currentItems?.filter(item =>
        item.email.toLowerCase().includes(searchText.toLowerCase())
      );
// -----------------------------------------------download function--------------------------
const [filterdata1, setFilterdata] = useState([]);
const handleDownload = () => {
    const agentList = filterdata1.map(val => [
        val.userId,
        val.agentName,
        val.email,
        val.agentPhoneNumber,
        val.ownerName,
        val.totalBookings
    ]);
    const headers = [
        "User Id",
        "Name",
        "Email",
        "Contact Number",
        "Owner Name",
        "Total Bookings"
    ];
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet([headers, ...agentList]);
        XLSX.utils.book_append_sheet(wb, ws, 'Agent List');
        XLSX.writeFile(wb, 'Magical_Vacation_Agent_list.xlsx');

}
    return (
        <>
            <StyledDashboardContainer open={open}>
                <h2 style={{ fontSize: "18px", color: "#00081d", fontWeight: "500", padding: "10px" }}>Agents</h2>
                <div className="agent-container-parent">
                    <div className="agnet-header">
                        <div className="search-from-list">
                            <input type="text" placeholder="Search..." onChange={(e) => {
                                e.preventDefault();
                                setSearchText(e.target.value);
                            }} />
                            <TbSearch style={{ color: "skyblue" }} />
                        </div>
                        <div className="agent-header-button">
                            {/* <button><img src="shareicon.svg" /> Share</button> */}
                            <button onClick={handleDownload}><img src="downloadicon.svg" /> Download</button>
                        </div>
                    </div>
                    {isLoading ? (
                        <div className="loader"></div>) : (
                        <>
                            <div className="agent-table">
                                <table>
                                    <thead>
                                        <tr>
                                            <td>Id</td>
                                            <td>Name</td>
                                            <td>Email</td>
                                            <td>Mob. no.</td>
                                            <td>Owner Name</td>
                                            <td>bookings</td>
                                            <td>Actions</td>
                                        </tr>
                                    </thead>
                                    {filterdata?.map((val, id) => {
                                        return <>
                                            <tbody key={id}>
                                                <tr>
                                                    <td>{val.userId}</td>
                                                    <td>{val.agentName}</td>
                                                    <td>{val.email}</td>
                                                    <td>{val.agentPhoneNumber}</td>
                                                    <td>{val.ownerName}</td>
                                                    <td>{val.totalBookings}</td>
                                                    <td onClick={()=>{
                                                        setAgent(true);
                                                        setAgentid(val._id);
                                                        setAgentName(val.agentName);
                                                        setAgentEmail(val.email);
                                                        setAgentMob(val.agentPhoneNumber);
                                                        setAgentUserId(val.userId);
                                                        setOwnerName(val.ownerName);
                                                        setVatNumber(val.vatNumber);
                                                        setTotalBooking(val.totalBookings);
                                                        setAgentCity(val.address);
                                                        setTotalRevenue(val.revenue);
                                                    }}>
                                                        <a 
                                                        style={{textDecoration:"underline",
                                                        cursor:"pointer",
                                                        color:"skyblue"
                                                        }}>
                                                            view more
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </>
                                    })}
                                </table>

                            </div>
                        </>)}
                </div>
                <StyledPaginationContainer>
                    <Stack spacing={1}>
                        <Pagination
                            count={Math.ceil((agentList && agentList?.length) / perPage)}
                            // count={1}
                            page={currentPage}
                            onChange={(event, value) => setCurrentPage(value)}
                            variant="outlined"
                            color="primary"
                            boundaryCount={1}
                        />
                    </Stack>
                </StyledPaginationContainer>
            </StyledDashboardContainer>
            {agent && <AgentDetails close={close} agentId={agentid}
             agentName={agentName}
             agentEmail={agentEmail}
             agentMob={agentMob}
             agentUserId={agentUserId}
             agentCity={agentCity}
             totalBooking={totalBooking}
             totalRevenue={totalRevenue}
             vatNumber={vatNumber}
             ownerName={ownerName}
            />}
        </>
        
    );
};

export default Agent;
