import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import "./TransferBookingDetail.css";

import { updateBookingStatus } from "../../services/bookingService";
import { APIPath, token } from "../../config/api";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function TransferBookingDetail({isOpen,onClose,id,}) {
  const [reasonCancel, setReasonCancel] = useState(false);
  const [raesonReject, setReasonReject] = useState(false);
  const [reasonPutHold, setReasonPutHold] = useState(false);
  const [reasonPaymentLink, setReasonPaymentLink] = useState(false);
  const [reasonMessage, setReasonMessage] = useState("");
  const [transferData, setTransferData] = useState();
  const [transfer,setTransfer]=useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setshowModal1] = useState(true)
  const [gatewayType, setGatewayType] = useState("");

  useEffect(() => {
    fetch(
      `${APIPath}/api/v1/agency/bookings/transfers?id=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "GET",
        mode: "cors",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setTransferData(data.data);
        setTransfer(data.data[0].transfers)
      })
      .catch((err) => {
        alert(err);
      });
  }, []);
  
  const checkMessage = () => {
    setReasonMessage("");
  };
  const sendPaymentLink = (gatewayType) => {
    fetch(
      `${APIPath}/api/v1/agency/payments/transfer/payment-link?id=${id}&gatewayType=${gatewayType}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "POST",
        mode: "cors",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data.statusCode);
        if (data.statusCode === 200) {
          alert("Payment Link Successfully sent to Agent...");
          // onPaymentLinkSent();
          onClose();
        } else {
          alert("Something Went wrong, Please try again!!");
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const updateStatus = async (val, reasonMessage) => {
    let data = {};

    if (val === "On Hold" || val === "Cancelled" || val === "Rejected") {
      data["status"] = val;
      data["reason"] = reasonMessage;
    }

    try {
      const datas = await updateBookingStatus(data, id);
      alert("API called successfully with updated data:", datas);
      onClose();
    } catch (error) {
      alert("Error while calling update API:", error);
    }
  };

  const handleGatewaySelection = (selectedGateway) => {
    setGatewayType(selectedGateway);
    setShowModal(false);
    sendPaymentLink(selectedGateway);
  };

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={isOpen}>
        Open full-screen dialog
      </Button>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <div className="transfer-details-container">
          {transferData?.map((val, id) => {
            return (
              <>
                <div className="transfer-lead-passenger-details">
                  <h2>Lead Passenger Details</h2>
                  <div className="transfer-lead-passenger-detail">
                    <div className="lead-passenger-container">
                      <p>Name</p>
                      <h3 className="blur-text">{val.customerDetails?.name}</h3>
                    </div>
                    <div className="lead-passenger-container">
                      <p>Phone Number</p>
                      <h3>+{val.customerDetails?.phone}</h3>
                    </div>
                    <div className="lead-passenger-container">
                      <p>Email</p>
                      <h3 className="blur-text">{val.customerDetails?.email}</h3>
                    </div>
                    <div className="lead-passenger-container">
                      <p>Total Passengers</p>
                      <h3>
                        {parseInt(transfer?.[0]?.numberOfAdults) +
                          parseInt(transfer?.[0]?.numberOfChildrens)}
                      </h3>
                    </div>
                  </div>
                </div>
                <hr/>
                <div className="transfer-lead-passenger-details">
                  <h2>Transfer Details</h2>
                  <div className="transfer-lead-passenger-detail">
                    <div className="lead-passenger-container">
                      <p>Trip Type</p>
                      <h3>{transfer?.[0]?.selectedTripType}</h3>
                    </div>
                    <div className="lead-passenger-container">
                      <p>Vehicle</p>
                      <h3>{val.vehicle?.name}</h3>
                    </div>
                    <div
                      className="lead-passenger-container"
                      // style={{ width: "200px" }}
                    >
                      <p>Pickup</p>
                      <h3>{transfer?.[0]?.InOut[0]?.from}</h3>
                    </div>
                    <div className="lead-passenger-container">
                      <p>Pickup Date & Time</p>
                      <h3 style={{ width: "200px" }}>
                        {transfer?.[0]?.InOut[0]?.arrivalPickupTime?.split("T")[0]}
                        &nbsp;
                       ({transfer?.[0]?.InOut[0]?.pickupTimeForArrival})
                      </h3>
                    </div>
                    <div className="lead-passenger-container">
                      <p>Drop off</p>
                      <h3>{transfer?.[0]?.InOut[0]?.to}</h3>
                    </div>
                    {(transfer?.[0]?.selectedTripType === 'ROUND_TRIP') && (
                    <div className="lead-passenger-container">
                       <p>Arrival Pickup Date & Time</p>
                       <h3 style={{ width: "200px" }}>{transfer?.[0]?.InOut[1]?.departurePickupTime?.split("T")[0]}
                       &nbsp;
                       ({transfer?.[0]?.InOut[1]?.pickupTimeForDeparture})
                       </h3>
                    </div>
                    )}
                  </div>
                </div>
                <hr/>
                <div className="transfer-lead-passenger-details"
                  style={{ display: "flex",gap:"2rem",justifyContent:"space-between" }}
                >
                  <div>
                    <h2>Agent Details</h2>
                    <div className="transfer-lead-passenger-detail">
                      <div className="lead-passenger-container">
                        <p>Name</p>
                        <h3 style={{ textTransform: "capitalize" }}>
                          {val.agentName}
                        </h3>
                      </div>
                      <div className="lead-passenger-container">
                        <p>Contact Number</p>
                        <h3>+{val.agentPhoneNumber}</h3>
                      </div>
                      <div className="lead-passenger-container">
                        <p>Agent Email</p>
                        <h3 
                        >{val.agentEmail}</h3>
                      </div>
                    </div>
                  </div>
                  {val.vehicle.vehicleProviderName?.length > 0 && (
                  <div>
                    <h2>Vehicle Provider Details</h2>
                    <div className="transfer-lead-passenger-detail">
                      <div className="lead-passenger-container">
                        <p>Name</p>
                        <h3 style={{ textTransform: "capitalize" }}>
                          {val.vehicle.vehicleProviderName}
                        </h3>
                      </div>
                      <div className="lead-passenger-container">
                        <p>Mobile No.</p>
                        <h3>{val.vehicle.vehicleProviderMobile}</h3>
                      </div>
                      <div className="lead-passenger-container">
                        <p>Email</p>
                        <h3>{val.vehicle.vehicleProviderEmail}</h3>
                      </div>
                    </div>
                  </div>
                  )}
                </div>
                <hr/>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="transfer-lead-passenger-details">
                    <h2>Price Breakup</h2>
                    <div className="transfer-lead-passenger-detail">
                      <div className="lead-passenger-container">
                        <p>Base fare</p>
                        <h3>AED {val.totalCost}</h3>
                      </div>
                      <div className="lead-passenger-container">
                        <p>VAT</p>
                        <h3>5%</h3>
                      </div>
                      <div className="lead-passenger-container">
                        <p>Total fare</p>
                        <h3>AED {parseInt(val.totalCost) + (((val.totalCost*5)/100))}</h3>
                      </div>
                    </div>
                  </div>
                  <div className="transfer-lead-passenger-details">
                    <h2>Payment Status</h2>
                    <div className="transfer-lead-passenger-detail">
                      {showModal1 && (
                        <div className="modal">
                          <div className="modal-content">
                            <select
                              onChange={(e) => {
                                setGatewayType(e.target.value)
                                setShowModal(true)
                                setshowModal1(false)
                              }}
                            >
                              <option value="" disabled> Select Getway</option>
                              <option value="STRIPE">Stripe</option>
                              <option value="TELR">Telr</option>
                            </select>
                            {/* <button 
                          style={{background:"none",border:"none",fontSize:"1.5rem", cursor:"pointer"}}
                          onClick={() => setShowModal(!showModal)}>
                          <IoMdClose/>
                          </button> */}
                          </div>
                        </div>
                      )}
                      {showModal && (
                        <div className="lead-passenger-container">
                          <p>Send Link</p>
                          <button
                            id="payment-link"
                            onClick={() => {
                              handleGatewaySelection(gatewayType)
                            }}
                          >
                            Send
                          </button>
                        </div>
                      )}
                      <div className="lead-passenger-container">
                        <p>Status</p>
                        <button id="payment-status">{val.paymentStatus}</button>
                      </div>
                    </div>

                  </div>
                </div>
              </>
            );
          })}
          <div className="transfer-details-button">
            <button
              id="cancel"
              onClick={() => {
                setReasonCancel(true);
              }}
            >
              Cancel
            </button>
            <button
              id="reject"
              onClick={() => {
                setReasonReject(true);
              }}
            >
              {" "}
              <img src="/reject.png" />
              &nbsp; Reject
            </button>
            <button
              id="on-hold"
              onClick={() => {
                setReasonPutHold(true);
              }}
            >
              <img src="/Pause.png" />
              &nbsp; On Hold
            </button>
            <button
              id="approve"
              onClick={() => {
                setReasonPaymentLink(true);
              }}
            >
              <img src="/Approve.png" />
              &nbsp; Approve
            </button>
          </div>
        </div>
        {reasonCancel && (
          <div className="transfer-reason-parent">
            <div className="transfer-raeson">
              <div className="reason-topbar">
                <p>Reason for cancellation</p>
                <p
                  id="cencel"
                  onClick={() => {
                    setReasonCancel(false);
                  }}
                >
                  <IoMdClose />
                </p>
              </div>
              <div className="reason-details">
                <textarea
                  placeholder="Write Reason..."
                  value={reasonMessage}
                  onChange={(e) => {
                    setReasonMessage(e.target.value);
                  }}
                />
              </div>
              <div className="reason-button">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    updateStatus("Cancelled", reasonMessage);

                    checkMessage();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
        {raesonReject && (
          <div className="transfer-reason-parent">
            <div className="transfer-raeson">
              <div className="reason-topbar">
                <p>Reason for Rejection</p>
                <p
                  id="cencel"
                  onClick={() => {
                    setReasonReject(false);
                  }}
                >
                  <IoMdClose />
                </p>
              </div>
              <div className="reason-details">
                <textarea
                  placeholder="Write Reason for..."
                  value={reasonMessage}
                  onChange={(e) => {
                    setReasonMessage(e.target.value);
                  }}
                />
              </div>
              <div className="reason-button">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    updateStatus("Rejected", reasonMessage);
                    checkMessage();
                  }}
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
        )}
        {reasonPutHold && (
          <div className="transfer-reason-parent">
            <div className="transfer-raeson">
              <div className="reason-topbar">
                <p>Reason for Putting Hold</p>
                <p
                  id="cencel"
                  onClick={() => {
                    setReasonPutHold(false);
                  }}
                >
                  <IoMdClose />
                </p>
              </div>
              <div className="reason-details">
                <textarea
                  placeholder="Write Reason..."
                  value={reasonMessage}
                  onChange={(e) => {
                    setReasonMessage(e.target.value);
                  }}
                />
              </div>
              <div className="reason-button">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    updateStatus("On Hold", reasonMessage);
                    checkMessage();
                  }}
                >
                  Put on Hold
                </button>
              </div>
            </div>
          </div>
        )}
        {reasonPaymentLink && (
          <div className="transfer-reason-parent">
            <div className="transfer-raeson">
              <div className="reason-topbar">
                <p>Send the Payment link for Confirmation</p>
                <p
                  id="cencel"
                  onClick={() => {
                    setReasonPaymentLink(false);
                  }}
                >
                  <IoMdClose />
                </p>
              </div>
              <div className="reason-button">
                {/* <button id="send-link-later">Send link later</button>{" "} */}
                {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                <button
                  onClick={() => {
                    sendPaymentLink("STRIPE");
                  }}
                >
                  Send Payment link now
                </button>
              </div>
            </div>
          </div>
        )}

        {/* <List>
          <ListItemButton>
            <ListItemText primary="Phone ringtone" secondary="Titania" />
          </ListItemButton>
          <Divider />
          <ListItemButton>
            <ListItemText
              primary="Default notification ringtone"
              secondary="Tethys"
            />
          </ListItemButton>

          
        </List> */}
      </Dialog>
    </React.Fragment>
  );
}