import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import { fetchPaymentLinks } from "../../../services/paymentService";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  Button,
  CircularProgress,
  Paper,
  Stack,
  Pagination,
  TextField,
} from "@mui/material";

import CreatePaymentLinkForm from "./CreatePaymentLinkForm"; 

import toast, { Toaster } from "react-hot-toast";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#192A56",

    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme, open }) => ({
  width: open ? "80%" : "94%",
  transition: "width 0.3s ease",
  paddingTop: "7px",
  marginLeft: open ? "243px" : "69px",
  position: "relative",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
  overflow: "hidden",
  marginBottom: "20px",
}));

const TableHeadContainer = styled(TableHead)({
  color: "white",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
});

const StyledTable = styled(Table)({
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
  overflow: "hidden",
});

const StyledPaginationContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  marginTop: "20px",
});



function PaymentLinkList({ open }) {
  const [paymentLinks, setPaymentLinks] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const [editedPackage, setEditedPackage] = React.useState(null);

  const [isCreateLinkModalOpen, setCreateLinkModalOpen] = React.useState(false);

  const [updateTrigger, setUpdateTrigger] = React.useState(false);
  const updateParent = () => {
    console.log("update function called");
    setUpdateTrigger((prev) => !prev);
  };

  const handleCreateModalOpen = () => {
    setCreateLinkModalOpen(true);
  };

  const handleCreateModalClose = () => {
    setCreateLinkModalOpen(false);
  };

  const handleEditFormClose = () => {
    setEditedPackage(null);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchPaymentLinks();
        console.log(data.data);
        setPaymentLinks(data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, [updateTrigger]);
  const handleDelete = async (packageId) => {
    console.log("handle delete");

    try {
      const toastId = toast.error(
        <div>
          <p>Are you sure you want to delete this package?</p>
          <div style={{ marginTop: "10px" }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleDeleteConfirmed(packageId, toastId)}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              onClick={() => toast.dismiss(toastId)}
              style={{ marginLeft: "10px" }}
            >
              Cancel
            </Button>
          </div>
        </div>,
        {
          autoClose: false,
          closeButton: false,
        }
      );
    } catch (error) {
      console.error("Error deleting package:", error.message);
    }
  };

  const handleDeleteConfirmed = async (packageId, toastId) => {
    try {
      console.log("Deleting package...");
      toast.success("Package deleted successfully.");
      toast.dismiss(toastId);
    } catch (error) {
      console.error("Error deleting package:", error.message);
    }
  };

  const CenteredHeaderCell = ({ children }) => {
    const cellStyles = {
      textAlign: "center",
    };

    return (
      <StyledTableCell style={cellStyles} align="right">
        {children}
      </StyledTableCell>
    );
  };
  function trimDescription(description) {
    console.log("DESCRIPTION", description);
    const maxLength = 20;
    const truncatedDescription =
      description?.length > maxLength
        ? description?.substring(0, maxLength)
        : description;

    return description?.length > maxLength
      ? truncatedDescription + "..."
      : truncatedDescription;
  }
  return (
    <StyledTableContainer component={Paper} open={open}>
      {editedPackage && (
        <CreatePaymentLinkForm
          editedPackage={editedPackage}
          onClose={handleEditFormClose}
          updateParent={updateParent}
        />
      )}
      {isCreateLinkModalOpen && (
        <CreatePaymentLinkForm
          isOpen={handleCreateModalOpen}
          onClose={handleCreateModalClose}
          updateParent={updateParent}

        />
      )}
      <Toaster position="top-center" reverseOrder={false} />
      <div
        style={{
          marginBottom: 16,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          // value={searchTerm}
          // onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button
          variant="contained"
          onClick={handleCreateModalOpen}
          color="primary"
          sx={{
            backgroundColor: "#192A56",
            color: "white",
            borderRadius: "4px",
          }}
        >
          Create Payment Link
        </Button>
      </div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
            margin: 0,
            padding: 0,
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <StyledTable>
            <TableHead>
              <TableRow>
                <CenteredHeaderCell align="right">Date</CenteredHeaderCell>
                <CenteredHeaderCell align="right">Name</CenteredHeaderCell>
                <CenteredHeaderCell align="right">Email</CenteredHeaderCell>
                <CenteredHeaderCell align="right">Amount</CenteredHeaderCell>

                <CenteredHeaderCell align="right">Link</CenteredHeaderCell>

                <CenteredHeaderCell align="right">Status</CenteredHeaderCell>

                <CenteredHeaderCell align="right">Action</CenteredHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentLinks.map((link) => (
                <TableRow key={link._id}>
                  {/* <CenteredHeaderCell align="right">
                    <img
                      src={pkg.thumbnailImage}
                      alt={`Image for ${pkg.thumbnailImage}`}
                      style={{ width: 30, height: 30, borderRadius: "50%" }}
                    />
                  </CenteredHeaderCell> */}
                  <CenteredHeaderCell align="right">
                    <Tooltip title={link.createdAt}>
                      {link.createdAt}
                    </Tooltip>
                  </CenteredHeaderCell>
                  <CenteredHeaderCell align="right">
                    <Tooltip title={link.name}>
                      {link.name}
                    </Tooltip>
                  </CenteredHeaderCell>

                  <CenteredHeaderCell align="right">
                    <Tooltip title={link.email}>
                      {link.email}
                    </Tooltip>
                  </CenteredHeaderCell>

                  <CenteredHeaderCell align="right">
                    <Tooltip title={link.amount}>
                      {link.amount}
                    </Tooltip>
                  </CenteredHeaderCell>

                  <CenteredHeaderCell align="right">
                    <Tooltip title={link.link}>
                      {link.link}
                      {/* {trimDescription(link.link)} */}
                    </Tooltip>
                  </CenteredHeaderCell>

                  <CenteredHeaderCell align="right">
                    <Tooltip title={link.name}>
                      {trimDescription(link.name)}
                    </Tooltip>
                  </CenteredHeaderCell>




                  <CenteredHeaderCell align="right">
                    <EditIcon
                      style={{ cursor: "pointer", marginRight: 5 }}
                      // onClick={() => handleEdit(link._id)}
                    />
                    <DeleteIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDelete(link._id)}
                    />
                  </CenteredHeaderCell>
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>

          <StyledPaginationContainer>
            <Stack spacing={1}>
              <Pagination count={1} variant="outlined" color="primary" />
            </Stack>
          </StyledPaginationContainer>
        </>
      )}
    </StyledTableContainer>
  );
}

export default PaymentLinkList;
