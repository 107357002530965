import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";
import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import AllTable from "./AllTable"; // Import your table components
import DraftsTable from "./DraftsTable";
import PendingTable from "./PendingTable.jsx";
import PaidTable from "./PaidTable";

const StyledDashboardContainer = styled(Grid)(({ theme, open }) => ({
  marginTop: "1%",
  marginLeft: open ? theme.spacing(29) : theme.spacing(7),
  width: open ? "80%" : "94%",
  transition: "margin-left 0.3s ease",
}));

const Payment = ({ open }) => {
  const [value, setValue] = React.useState("All");
  const [textColour, setTextColour] = React.useState("#2B2B52");

  const handleChange = (event, newValue) => {
    if(newValue == "All"){
      console.log(newValue)
      setValue(newValue);
      setTextColour("#2B2B52")
    }
    if(newValue == "Drafts"){
      console.log(newValue)
      setValue(newValue);
      setTextColour("#FF0000")

    }
    if(newValue == "Pending"){
      console.log(newValue)
      setValue(newValue);
      setTextColour("orange")

    }
    if(newValue == "Paid"){

      console.log(newValue)
      setValue(newValue);
      setTextColour("green")

    }
  };

  const renderTable = () => {
    switch (value) {
      case "All":
        return <AllTable />;
      case "Drafts":
        return <DraftsTable />;
      case "Pending":
        return <PendingTable />;
      case "Paid":
        return <PaidTable />;
      default:
        return null;
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "all":
        return "#2B2B52";
      case "drafts":
        return "red";
      case "paid":
        return "green";
      case "failed":
        return "red";
      case "pending":
        return "orange";
      default:
        return "black";
    }
  };

  const CustomTab = ({ label, status }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: 10,
          height: 10,
          borderRadius: "50%",
          backgroundColor: getStatusColor(status),
          marginRight: 8,
        }}
      ></div>
      <span>{label}</span>
    </div>
  );

  return (
    <StyledDashboardContainer container spacing={2} open={open}>
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor={textColour}
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab label={<CustomTab label="All" status="all" />} value="All" />
          <Tab
            label={<CustomTab label="Drafts" status="drafts" />}
            value="Drafts"
          />
          <Tab
            label={<CustomTab label="Pending" status="pending" />}
            value="Pending"
          />
          <Tab label={<CustomTab label="Paid" status="paid" />} value="Paid" />
        </Tabs>
      </Box>
      {renderTable()}
    </StyledDashboardContainer>
  );
};

export default Payment;
