import React from "react";
import {BrowserRouter as Router,Navigate,} from "react-router-dom";
import MiniDrawer from "./components/common/Sidebar"; // Assuming your Sidebar component is defined here
import AllRoutes from "./routes/AllRoutes";
import { useSelector } from "react-redux";
import './App.css';
function App() {
  const [open, setOpen] = React.useState(true);
  const { isLoggedIn } = useSelector((state) => state.auth);

  return (
    <Router>
      {isLoggedIn ? (
        <React.Fragment>
          <MiniDrawer open={open} setOpen={setOpen} />
        </React.Fragment>
      ) : (
        <Navigate to="/login" />
      )}

      <AllRoutes open={open} />
    </Router>
  );
}

export default App;
