import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Paper, } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import { IoIosSearch } from "react-icons/io";
import {
    Stack,
    Pagination,
} from "@mui/material";
import { token, APIPath } from "../../config/api";
import HotelForm from "./HotelForm";

const StyledTableContainer = styled(TableContainer)(({ theme, open }) => ({
    marginTop: "1%",
    marginLeft: open ? theme.spacing(29) : theme.spacing(7),
    width: open ? "80%" : "94%",
    height: "100vh",
    transition: "margin-left 0.3s ease",
    background: "rgba(204,204,204,0.2)",
    padding: "1rem 1rem 1rem 1rem",
    boxShadow: "none",
}));
const StyledPaginationContainer = styled("div")({
    background: "#fff",
    margin: "2rem auto 0 auto;",
    zIndex: 1,
    background: "none",
    display: "flex",
    justifyContent: "center"
});

const AirportList = ({ open }) => {
    const [hotelForm, setHotelForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hotelList,setHotelList]=useState(null);
    const [searchText, setsearchText] = React.useState('');

   const HotelList=()=>{
    setIsLoading(true)
    fetch(`${APIPath}/api/v1/agency/hotels`, {
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
      },
      method: 'GET',
      mode: 'cors',
  })
      .then((res) => res.json())
      .then((data) => {
          console.log(data);
          setHotelList(data.data);
          setIsLoading(false)
      })
      .catch((err) => {
          alert(err)
          setIsLoading(false);
      })
   }
    useEffect(() => {
      HotelList();
    }, [])

    const filterdata = hotelList?.filter(item =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
      );
    const onClose = () => {
      setHotelForm(false);
      HotelList();
    }
    const onShow = () => {
      setHotelForm(true)
    }
    const deleteHotel=(id)=>{
        let confirm = window.confirm("Are you sure you want to delete this hotel? ")
        if(confirm){
            setIsLoading(true);
            fetch(`${APIPath}/api/v1/agency/hotels?id=${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                method: 'DELETE',
                mode: 'cors',
            })
                .then((res) => res.json())
                .then((data) => {
                    alert("Hotel deleted...");
                    HotelList();
                    setIsLoading(false)
                })
                .catch((err) => {
                    alert(err)
                    setIsLoading(false)
                })
        }
        else {
            return;
        }

    }

    const Uppercase = (str) => str.toUpperCase();
    const capitalize = (str) => {
        if (typeof str !== 'string') return '';
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      };

    return (
        <>
            <StyledTableContainer component={Paper} open={open}>
                <div style={{ display: "flex", justifyContent: "space-between", padding: "10px 20px" }}>
                    <h2 style={{ fontSize: "18px" }}>Hotel</h2>
                    <button className="add-hotel"
                        onClick={onShow}
                    >Add Hotel</button>
                </div>
                <div className="package-search-table">
                    <div className="package-search">
                        <input type="text" placeholder="Search.."
                            value={searchText} onChange={(e) => {
                                setsearchText(e.target.value)
                            }}
                        />
                        <IoIosSearch style={{ cursor: "pointer", color: "skyblue" }} />
                    </div>
                    <div className="package-table" style={{
                        width: "100%",
                    }}>
                        <table style={{width:"100%"}}>
                            <thead>
                                <tr>
                                    <th>Serial No.</th>
                                    <th>Country Name</th>
                                    <th>City Name</th>
                                    <th>Hotel Name</th>
                                    <th>Hotel Address</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            {isLoading ? <div className="loader"></div> :
                            <>
                            <tbody>
                                {filterdata?.map((val, id) => {
                                    return <>
                                        <tr key={id}>
                                            <td>{id + 1}</td>
                                            <td>{Uppercase(val.countryName)}</td>
                                            <td>{capitalize(val.cityName)}</td>
                                            <td>{capitalize(val.name)}</td>
                                            <td>{val.location?.address} {val.location?.zipCode}</td>
                                            <td> <button id="country-delete"
                                            onClick={()=>{
                                                deleteHotel(val._id)
                                            }}
                                            >
                                                <img src="./deleteicon.svg" />
                                            </button>
                                            </td>
                                        </tr>
                                    </>
                                })}
                            </tbody>
                            </>}
                        </table>
                    </div>
                </div>
                {/* <StyledPaginationContainer>
                    <Stack spacing={1}>
                        <Pagination
                            count={Math.ceil((vehicleList && vehicleList?.length) / perPage)}
                            // count={1}
                            page={currentPage}
                            onChange={(event, value) => setCurrentPage(value)}
                            variant="outlined"
                            color="primary"
                            boundaryCount={1}
                        />
                    </Stack>
                </StyledPaginationContainer> */}
            </StyledTableContainer>
            {/* {newCountry && <CityForm onClose={onClose} onShow={onShow} />} */}
            {hotelForm && <HotelForm onClose={onClose} onShow={onShow} HotelList={HotelList} />}
        </>
    );
}

export default AirportList;
