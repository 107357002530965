import React, { useEffect } from "react";
import BootstrapDialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";
import { generatePaymentLinkAndSendMail } from "../../../services/paymentService"; 
import toast, { Toaster } from "react-hot-toast";
import { fetchPackages } from "../../../services/packageService";
import { fetchAttractions } from "../../../services/attractionService";
import { fetchLandCombos } from "../../../services/landComboService";

import CircularProgress from "@mui/material/CircularProgress";

const names = ["Package 1", "Package 2", "Package 3"]; 
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 224,
      width: 250,
    },
  },
};

export default function PaymentLinkForm({ isOpen, onClose ,updateParent }) {
  const [selectedProducts, setSelectedProducts] = React.useState([]); 
  const [linkData, setLinkData] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const [selectedType, setSelectedType] = React.useState("");

  const [options, setOptions] = React.useState([]);

  const [buttonText, setButtonText] = React.useState(
    "Send Payment Link To Mail"
  );

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    setLinkData((prevData) => ({
      ...prevData,
      [name]: checked
        ? type === "checkbox"
          ? [...prevData[name], value]
          : value
        : type === "number"
        ? parseInt(value, 10)
        : value,
    }));

    if (type === "checkbox") {
      setSelectedProducts((prevProducts) => {
        console.log(prevProducts);
        const selectedProductIds = Array.isArray(value) ? value : [value];

        return checked
          ? [
              ...prevProducts,
              ...options.filter((product) =>
                selectedProductIds.includes(product._id)
              ),
            ]
          : prevProducts.filter(
              (product) => !selectedProductIds.includes(product._id)
            );
      });
    }

    if (name === "type") {
      setSelectedType(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let data;
        switch (selectedType) {
          case 1:
            data = await fetchPackages();
            setOptions(data.data);
            break;
          case 2:
            data = await fetchAttractions();
            setOptions(data.data);
            break;
          case 3:
            data = await fetchLandCombos();
            setOptions(data.data);
            break;
          default:
            data = [];
            break;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setOptions([]);
      }
    };

    fetchData();
  }, [selectedType]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);
    setButtonText("sending mail please wait...");

    try {
      delete linkData["validity"];
      const data = await generatePaymentLinkAndSendMail(linkData);
      toast.success("Payment link generated successfully and sent to mail");
      updateParent();
    } catch (error) {
      console.error(error);
      toast.error("Error generating payment link please try again.");
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const getTypeLabel = (type) => {
    switch (type) {
      case 1:
        return "Package";
      case 2:
        return "Attractions";
      case 3:
        return "Land Combos";
      default:
        return "Select";
    }
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Create Payment Link
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            margin="normal"
            size="small"
            name="name"
            onChange={handleChange}
          />

          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            size="small"
            name="email"
            onChange={handleChange}
          />

          <TextField
            select
            label="Select Type"
            variant="outlined"
            fullWidth
            margin="normal"
            size="small"
            name="type"
            onChange={handleChange}
          >
            <MenuItem value={1}>Package</MenuItem>
            <MenuItem value={2}>Attraction</MenuItem>
            <MenuItem value={3}>Land&Combo</MenuItem>
          </TextField>

          <FormControl
            variant="outlined"
            fullWidth
            margin="normal"
            size="small"
          >
            <InputLabel id="demo-multiple-checkbox-label">
              {selectedType === 1
                ? "Select Package"
                : selectedType === 2
                ? "Select Attractions"
                : selectedType === 3
                ? "Select Land Combos"
                : "Select"}
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={selectedProducts.map((product) => product._id)}
              onChange={handleChange}
              input={
                <OutlinedInput label={`Select ${getTypeLabel(selectedType)}`} />
              }
              renderValue={(selected) =>
                selectedProducts
                  .filter((product) => selected.includes(product._id))
                  .map((product) => product.title)
                  .join(", ")
              }
              MenuProps={MenuProps}
              name="productIds"
            >
              {options.map((product) => (
                <MenuItem key={product._id} value={product._id}>
                  <Checkbox
                    checked={selectedProducts.some(
                      (selectedProduct) => selectedProduct._id === product._id
                    )}
                    onChange={handleChange}
                    value={product._id}
                    name="productIds"
                  />
                  <ListItemText primary={product.title} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            select
            label="Select Currency"
            variant="outlined"
            fullWidth
            margin="normal"
            size="small"
            name="currency"
            onChange={handleChange}
          >
            <MenuItem value="USD">USD</MenuItem>
            <MenuItem value="AED">AED</MenuItem>
            <MenuItem value="INR">INR</MenuItem>
          </TextField>

          <TextField
            select
            label="Valid For"
            variant="outlined"
            fullWidth
            margin="normal"
            size="small"
            name="validity"
            onChange={handleChange}
          >
            <MenuItem value="1 Day">1 Day</MenuItem>
            <MenuItem value="1 Week">1 Week</MenuItem>
            <MenuItem value="1 Month">1 Month</MenuItem>
          </TextField>

          <TextField
            label="Amount"
            variant="outlined"
            fullWidth
            margin="normal"
            size="small"
            name="amount"
            type="number"
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            autoFocus
            onClick={handleSubmit}
            style={{
              borderRadius: 35,
              backgroundColor: "#21b6ae",
              padding: "6px 10px",
              fontSize: "15px",
              disabled: { loading }, 
            }}
          >
            {buttonText}
            {loading && <CircularProgress disableShrink />}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
