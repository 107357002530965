import * as React from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from "recharts";

export function LandcombographAnnually(open) {
    const AnnuallyBookingsData = [
        { year: 2023, bookings: 390 },
        { year: 2024, bookings: 1405 },
        { year: 2025, bookings: 2360 },
        { year: 2026, bookings: 2465 },
        { year: 2027, bookings: 890 },
        { year: 2028, bookings: 1200 },
        { year: 2029, bookings: 180 },
        { year: 2030, bookings: 1880 },
    ];

    return (
        <LineChart width={open ? 900 : 1100} height={300} data={AnnuallyBookingsData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="year" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
            type="monotone"
            dataKey="bookings"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
        />
    </LineChart>
    );
}
export function LandcombographhWeekly(open) {
    const AnnuallyBookingsData = [
        { year: 2023, bookings: 390 },
        { year: 2024, bookings: 1405 },
        { year: 2025, bookings: 2360 },
        { year: 2026, bookings: 2465 },
        { year: 2027, bookings: 890 },
        { year: 2028, bookings: 1200 },
        { year: 2029, bookings: 180 },
        { year: 2030, bookings: 1880 },
    ];
    return (
        <LineChart width={open ? 900 : 1100} height={300} data={AnnuallyBookingsData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
                type="monotone"
                dataKey="bookings"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
            />
        </LineChart>
    );
}
export function LandcombographMonthly (open) {
    const AnnuallyBookingsData = [
        { year: 2023, bookings: 390 },
        { year: 2024, bookings: 1405 },
        { year: 2025, bookings: 2360 },
        { year: 2026, bookings: 2465 },
        { year: 2027, bookings: 890 },
        { year: 2028, bookings: 1200 },
        { year: 2029, bookings: 180 },
        { year: 2030, bookings: 1880 },
    ];
    return (
        <LineChart width={open ? 900 : 1100} height={300} data={AnnuallyBookingsData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
                type="monotone"
                dataKey="bookings"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
            />
        </LineChart>
    );
}