import * as React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectIsLoggedIn } from "../../store/authSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const settings = ["Profile", "Account", "Dashboard", "Logout"];

function Header() {
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };


  const handleCloseUserMenu = (setting) => {
    setAnchorElUser(null);
    if (setting === "Logout") {
      console.log("Logout clicked, perform logout logic", setting);
      if (isLoggedIn) {
        dispatch(logout());
      }
    }
  };

  return (
    <div>
      <ToastContainer />

      <Container maxWidth="xl" sx={{backgroundColor:"#fff"}}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1, marginLeft: -5,color:"blue",letterSpacing:"1.5px",fontWeight:"600" }}>
            {/* Magical Vacation */}
          </Typography>
          <div style={{display:"flex",justifyContent:"flex-end",paddingRight:"2rem",alignItems:"center"}}>
            <img src="notification.svg"/>
          </div>
          <Box>
            <Tooltip style={{ marginRight: "-35px" }} title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <img src="user1.svg" height={30} alt="User"/>
              </IconButton>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={() => handleCloseUserMenu(setting)}
                >
                  <Typography>{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </div>
  );
}

export default Header;
