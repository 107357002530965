const API_BASE_URL = "https://admin.magicalvacation.com/api/v1";

const fetchLandCombos = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/land_combos`);
    if (!response.ok) {
      throw new Error("Failed to fetch land_combos");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching land_combos:", error.message);
    throw error;
  }
};

export { fetchLandCombos };
