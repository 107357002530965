import React, { useEffect, useState } from "react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import './Packages.css';
import BookPackageDetails from "./BookPackageDetails";
import { token ,APIPath} from "../../../config/api";
import { styled } from "@mui/material/styles";
import {
    Stack,
    Pagination,
} from "@mui/material";

const StyledPaginationContainer = styled("div")({
    background: "#fff",
    margin: " 2rem auto 0 auto",
    zIndex:1,
    background:"none",
});

const PackagesList = ({searchText}) => {
    document.body.style.overflow='auto';
    const [pkgList, setPkgList] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [bookingDetails,setBookingDetails]=useState(false);
    const [bookpackageId,setBookPackageId]=useState('');
    const [packagesData, setPackagesData] = React.useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage] = useState(15);

    const packagelist = () => {
        fetch(`${APIPath}/api/v1/agency/bookings/packages`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            method: 'GET',
            mode: "cors",
        })
            .then((res) => res.json())
            .then((data) => {
                setPkgList(data.data);
                setPackagesData(data.data)
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching Packages list:', error);
                setIsLoading(false);
            });
    }
    useEffect(() => {
        packagelist();
    },[]);

    const indexOfLastItem = currentPage * perPage;
    const indexOfFirstItem = indexOfLastItem - perPage;
    const currentItems = pkgList && pkgList.slice(indexOfFirstItem, indexOfLastItem);

    const filterdata = currentItems?.filter(item =>
        item.bookingID.toLowerCase().includes(searchText.toLowerCase())
      );

    // const filteredPackages = packagesData.filter(item =>
    //     item.agentEmail.toLowerCase().includes(searchText.toLowerCase())
    //   );

    const seeBooking=(id)=>{
        setBookingDetails(true);
        setBookPackageId(id)
    }
    const closeBooking=()=>{
        setBookingDetails(false);
        packagelist();
    }

    return <>
        {isLoading ? (
            <div className="loader">

            </div>) : (
            <>
                {pkgList?.length > 0 ? (
                    <>
                        <div className="package-list-container">
                            
                            <table>
                                <thead>
                                    <tr>
                                        <th>Booking Id</th>
                                        <th>Agent Name</th>
                                        <th>Agent Email</th>
                                        <th>Booking Date</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filterdata?.map((val, id) => {
                                        return <>
                                            <tr key={id}>
                                                <td>{val.bookingID}</td>
                                                <td>{val.agentName}</td>
                                                <td>{val.agentEmail}</td>
                                                <td>{val.createdAt.split("T")[0]}</td>
                                                <td>{val.totalCost}</td>
                                                <td><p id={(val.bookingStatus === "Pending" && "pending") ||
                                                    (val.bookingStatus === "On Hold" && "on-hold") ||
                                                    (val.bookingStatus === "Awaiting Payment" && "Awaiting_Payment") ||
                                                    (val.bookingStatus === "Confirmed" && "confirm") ||
                                                    (val.bookingStatus === "Rejected" && "rejected")

                                                    }>{val.bookingStatus}</p> </td>
                                                <td>
                                                    <button
                                                    onClick={()=>{
                                                        seeBooking(val._id)
                                                    }}
                                                    >
                                                        <MdOutlineRemoveRedEye />
                                                    </button>
                                                </td>
                                            </tr>
                                        </>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </>
                ) : (
                    <div style={{marginLeft:"1rem"}}>
                        <h2>No Record Found</h2>
                    </div>
                )}
            </>
        )}
        {bookingDetails && <BookPackageDetails 
        onClose={closeBooking} 
        bookingId={bookpackageId} 
        /> }
        <StyledPaginationContainer>
                    <Stack spacing={1}>
                        <Pagination
                            count={Math.ceil((pkgList && pkgList.length) / perPage)}
                            // count={1}
                            page={currentPage}
                            onChange={(event, value) => setCurrentPage(value)}
                            variant="outlined"
                            color="primary"
                            boundaryCount={1}
                        />
                    </Stack>
        </StyledPaginationContainer>
    </>

}
export default PackagesList;